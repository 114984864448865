import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import availableLanguages from './available-languages';
import moment from "moment";
require('moment/locale/ru');
require('moment/locale/cs');

i18n.on('initialized', () => {
    if (i18n.languages && i18n.languages.length) {
        moment.locale(i18n.languages[0]);
    } else {
        moment.locale(availableLanguages[0]);
    }
});

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: availableLanguages[0],
        lowerCaseLng: true,
        cleanCode: true,
        load: "languageOnly",
        //debug: true,
        whitelist: availableLanguages,
        nonExplicitWhitelist: true,
        supportedLngs: availableLanguages,
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            caches: ['localStorage', 'cookie'],
            cookieMinutes: 300,
        },
    });

i18n.on('languageChanged', function(lng) {
    // if (lng === 'ru') {
    //     import('moment/locale/ru');
    // } else if (lng === 'cs') {
    //     import('moment/locale/cs');
    // }
    // moment.locale(localeMap[lng]);

});

export default i18n;