import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    navLinks,
    getGeneralSettings,
    saveGeneralSettings,
    getGeneralProperties
} from "../../services/settings";
import Content from "../../components/content/Content";
import {Grid} from "@material-ui/core";
import CheckboxField from "../../components/fields/CheckboxField";
import {withTranslation} from "react-i18next";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import IntegerField from "../../components/fields/IntegerField";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import _ from "lodash";

const PROPERTIES_COMPONENTS = {
    daysToBurnPaymentAfterBlocked: IntegerField,
    autoSendCert: CheckboxField,
};

const PROPERTIES_ADDITIONAL_PARAMETERS = {
    testTaskActionData: {
        multiline: true,
        rows: 3
    },
    testGroupPhones: {
        multiline: true,
        rows: 3
    }

}

const PROPERTIES_GRID_COLS = {
    testTaskActionData: 12
}

export default
@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withStyles(() => ({
    info: {
        marginTop: 10,
        paddingLeft: 10,
    }
}))
class GeneralSettings extends React.Component {
    state = {
        values: {},
        properties: {
            mainTab: [],
        },
        tabVal:0
    }

    componentDidMount() {
        getGeneralSettings()
            .then(response => {
                if (response.success) {
                    this.setState({
                        values: response.data
                    })
                } else {
                    this.props.showError('Can\'t get settings from server')
                }
            });
        getGeneralProperties()
            .then(response => {
                if (response.success) {
                    this.setState({
                        properties: {
                            mainTab: response.data
                        }
                    })
                }
            });
    }

    handleChange = event => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value,
            },
        });
    }

    renderProperties(tabName) {
        const { properties, values } = this.state;

        const components = [];

        _.forEach(properties[tabName], (property) => {
            let PropertyComponent = AnyField;
            let propertyAdditionalParameters = {};

            if (PROPERTIES_COMPONENTS[property.id]) {
                PropertyComponent = PROPERTIES_COMPONENTS[property.id];
            }

            if (PROPERTIES_ADDITIONAL_PARAMETERS[property.id]) {
                propertyAdditionalParameters = PROPERTIES_ADDITIONAL_PARAMETERS[property.id];
            }

            let gridCols = (!PROPERTIES_GRID_COLS[property.id]) ? 6 : parseInt(PROPERTIES_GRID_COLS[property.id]);

            components.push(<Grid key={property.id} item xs={gridCols}><PropertyComponent
                name={property.id}
                label={property.text}
                value={values[property.id]}
                onChange={this.handleChange}
                fullWidth
                {...propertyAdditionalParameters}
            /></Grid>);
        });

        return components;
    }

    handleSave = () => {
        saveGeneralSettings(this.state.values)
            .then(response => {
                if (response.success) {
                    this.setState({
                        values: response.data,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    tabHandleChange = (event, value) => {
        this.setState({
            tabVal: parseInt(value)
        });
    }

    render() {
        const { tabVal } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleSave}>Сохранить</ActionButton>
            </LeftBar>
            <Content title="Общие настройки">
                <Tabs value={tabVal} onChange={this.tabHandleChange}>
                    <Tab label="Общие" id={0} />
                </Tabs>
                <TabPanel index={0} value={tabVal}>
                    <Grid container spacing={2}>
                        {this.renderProperties('mainTab')}
                    </Grid>
                </TabPanel>
            </Content>
        </React.Fragment>
    }
}

class TabPanel extends React.Component {
    render() {
        const { children, value, index } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
            >
                {children}
            </div>
        );
    }
}