import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import messageDialogActions from './messageDialog-acions';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";

export default
@connect(state => ({
    open: state.message.open,
    message: state.message.message,
    title: state.message.title,
}), {
    hide: messageDialogActions.hide
})
@withTranslation()
class MessageDialog extends React.Component
{
    handleClose = () => {
        this.props.hide();
    };

    render() {
        const { title, open, message, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{title || t('Saving')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description" variant="subtitle1">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>OK</Button>
                </DialogActions>
            </Dialog>

        );
    }
}
