import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import ViewPage from "./ViewPage";

class ExecutorPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/executor"                component={ListPage}/>
                <Route       path="/executor/view"           component={ViewPage}/>
                <Route       path="/executor/edit"           component={ViewPage}/>
            </Switch>
        );
    }
}

export default ExecutorPage;