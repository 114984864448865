import React from 'react';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import moment from "moment";
import {getLabelTransform} from "../../styles";
import {Link} from "react-router-dom";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
const styles = theme => ({
    valueContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    value: {
        display: 'flex',
        maxWidth: '100%',
        overflow: 'auto',
        justifyContent: 'space-between',
        width: 'auto',
        alignItems: 'start',
        '& a': {
            marginRight: 15
        }
    },
    valueMultiline: {
        flexDirection: 'column'
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    withLabel2Lines: {
        marginTop:  theme.spacing(5),
    },
    label2Lines: {
        '&.shrink': {
            transform: getLabelTransform(-35),
        },
    },
    clickable: {
        cursor: 'pointer',
        borderBottom: '1px dotted'
    }
});

export default
@withStyles(styles)
class ShowField extends React.PureComponent
{
    static propTypes = {
        className: PropTypes.string,
        fullWidth: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.any,
        dateFormat: PropTypes.string,
        linkTo: PropTypes.string,
        endAdornment: PropTypes.any,
        label2Lines: PropTypes.bool,
        visible: PropTypes.bool,
        multiline: PropTypes.bool,
        flexWrap: PropTypes.bool,
        onClick: PropTypes.func,
        labelClassName: PropTypes.string,
        collapsible: PropTypes.bool,
        collapsed: PropTypes.bool
    };

    static defaultProps = {
        fullWidth: true,
        visible: true,
        multiline: false,
        flexWrap: false,
        collapsible: false,
        collapsed: true
    };

    state = {
        collapsed : this.props.collapsed
    }

    processValue = () => {
        let { value, dateFormat, multiline } = this.props;

        if (value && dateFormat) {
            value = moment(value).format(dateFormat);
        }

        if (multiline && typeof value === "string") {
            value = value.split("\n").map((item, itemIdx) => <div key={itemIdx}>{item}</div>)
        }

        return value === null || (typeof value !== 'number' && !value) ? <span>&nbsp;</span> : value;
    };

    collapseToggle = () => {
        this.setState({collapsed: !this.state.collapsed})
    }

    getCollapseIcon = () => {
        let styleDown = {
            position: "absolute",
            bottom: 0,
            right: 0,
        }

        let styleUp = {
            position: "absolute",
            bottom: "100%",
            right: 0,
        }

        return this.state.collapsed ?
            <ArrowDropDownIcon style={styleDown} />
            :
            <ArrowDropUpIcon style={styleUp}/>
    }

    render() {
        const { classes, fullWidth, className, label, linkTo, children, endAdornment,
            label2Lines, visible, multiline, flexWrap, onClick, labelClassName, collapsible } = this.props;

        if (visible) {
            return (
                <FormControl
                    className={classNames(classes.root, {
                        [classes.withLabel]: label,
                        [classes.withLabel2Lines]: label2Lines
                    }, className)}
                    fullWidth={fullWidth}
                    onClick={onClick}
                >
                    <span onClick={this.collapseToggle} >
                        {label ? <InputLabel shrink variant="outlined" classes={{
                            root: label2Lines ? classes.label2Lines : null,
                            shrink: 'shrink'
                        }} className={labelClassName}>{label}

                        </InputLabel> : null}

                        {collapsible ?
                            this.getCollapseIcon()
                            :
                            null
                        }
                    </span>
                    {collapsible && this.state.collapsed ?
                        null
                        :
                        <div className={classes.valueContainer}>
                            <div className={classNames(classes.value, {
                                [classes.valueMultiline]: multiline,
                                [classes.flexWrap]: flexWrap,
                                [classes.clickable]: onClick
                            })}>{children || (linkTo ?
                                <Link to={linkTo}>{this.processValue()}</Link> : this.processValue())}
                            </div>
                            {endAdornment}
                        </div>
                    }
                </FormControl>
            );
        }

        return null;
    };
}
