import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";

@withStyles(theme => ({
    columnSelector: {
        borderBottomColor: theme.palette.grey[400],
    },
    subColumnsHeader: {
        paddingBottom:  theme.spacing(0.5),
    },
    subColumn: {
        paddingTop:  theme.spacing(0.5),
        paddingBottom:  theme.spacing(1),

        borderRightWidth: 1,
        borderRightStyle: 'dashed',
        borderRightColor: theme.palette.grey[300],
        borderBottomColor: theme.palette.grey[400],

        '&:last-child': {
            borderRight: 'none',
        }
    },
    subColumnsRow: {
        height: 'auto',
    },
    column: {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.grey[400],
        borderBottomColor: theme.palette.grey[400],

        '&:last-child': {
            borderRight: 'none',
        }
    },
    headRow: {
        backgroundColor: theme.palette.grey[200],
    },
}))
@withTranslation()
class DataTableHead extends React.PureComponent {

    static propTypes = {
        numeration: PropTypes.bool
    }

    static defaultProps = {
        numeration: false,
    }

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    renderSelector = hasSubColumns => {
        const { onSelectAllClick, numSelected, rowCount, selector, classes } = this.props;

        if (selector) {
            return(
                <TableCell padding="checkbox" rowSpan={hasSubColumns ? 2 : null} className={classes.columnSelector}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
            );
        }
    };

    renderColumn = (column, hasSubColumns, isSubColumn = false, isLastSubColumn = false) => {
        const { order, orderBy, disableSorting, classes, t } = this.props;

        const style = {};

        if (column.minWidth) {
            style.minWidth = column.minWidth;
        }

        if (column.width) {
            style.width = column.width;
        }

        if (column.wordBreak) {
            style.wordBreak = 'break-word';
        }

        return <TableCell
            key={column.id}
            align={hasSubColumns && column.subColumns ? 'center' : column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={ (disableSorting || column.disableSorting) ? null : (orderBy === column.id ? order : false) }
            colSpan={hasSubColumns && column.subColumns ? column.subColumns.length : null}
            rowSpan={hasSubColumns && !column.subColumns ? 2 : null}
            className={(hasSubColumns && column.subColumns ? classes.subColumnsHeader : isSubColumn ? classes.subColumn : classes.column) + (isLastSubColumn || !isSubColumn ? ' ' + classes.column : '')}
            style={style}
        >
            {(disableSorting || column.disableSorting) ?
                t(column.label)
                :
                <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                >
                    {t(column.label)}
                </TableSortLabel>
            }
        </TableCell>;
    }

    render() {
        const { columnData, deleteColumn, classes, groupBy, numeration } = this.props;
        const columnsWithSubColumns = columnData.filter(column => column.subColumns);

        return <TableHead className={classes.headRow}>
            <TableRow className={columnsWithSubColumns.length ? classes.subColumnsRow : null}>
                {groupBy && <TableCell padding="checkbox" className={classes.columnSelector}>&nbsp;</TableCell>}
                {this.renderSelector()}
                {numeration && <TableCell padding="checkbox" className={classes.column}>&nbsp;</TableCell>}
                {deleteColumn ?
                    <TableCell padding="checkbox" className={classes.columnSelector}>&nbsp;</TableCell>
                    : null
                }
                {columnData.map(column => this.renderColumn(column, columnsWithSubColumns.length))}
            </TableRow>
            {columnsWithSubColumns.length ?
            <TableRow className={classes.subColumnsRow}>
                {columnsWithSubColumns.map(column => column.subColumns.map((subColumn, subIdx) => this.renderColumn(subColumn, false, true, subIdx === column.subColumns.length-1)))}
            </TableRow>
            : null
            }
        </TableHead>;
    }
}

DataTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columnData: PropTypes.array.isRequired,
    selector: PropTypes.bool.isRequired,
    disableSorting: PropTypes.bool,
    deleteColumn: PropTypes.bool,
    groupBy: PropTypes.bool
};

export default DataTableHead;
