import React from 'react';
import Button from "@material-ui/core/Button";
import * as PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

export default
@withStyles(theme => ({
    loading: {
        marginLeft:  theme.spacing(1),
    },
}))
class LoadingButton extends React.Component
{
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        loading: false,
    };

    render() {
        const { classes, className, onClick, disabled, loading, children, ...other } = this.props;

        return <Button variant="contained" color="primary" className={className} onClick={onClick} disabled={disabled} {...other}>
            {children}
            {loading ? <CircularProgress className={classes.loading} size={20}/> : null}
        </Button>
    }
}
