import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import ViewPage from "./ViewPage";

class TasksPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/tasks"                component={ListPage}/>
                <Route       path="/tasks/view"           component={ViewPage}/>
            </Switch>
        );
    }
}

export default TasksPage;