import * as queryString from 'query-string-object';
import baseRequest from "./baseReuqest";

export const navLinks = [
    { path: "/process", text: "Processes"},
];

export function getProcesses(page, per_page, order, order_by) {
    let url = '/api/process';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function loadProcess(id) {
    let url = `/api/process/${id}`;
    return baseRequest(url);
}

export function loadProcessByExecutorId(id) {
    let url = `/api/process-by-executor-id/${id}`;
    return baseRequest(url);
}

export function saveProcess(data) {
    console.log('что уходит', data)
    return baseRequest(
        `/api/process`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function sendPhonesToMakeDisposableProcess(data) {
    return baseRequest('/api/process-by-phones-list', {
        method: "POST",
        body: data,
    });
}

export function getResultDataForProcessFromErp(processId) {
    let url = `/api/get-process-data-from-erp/${processId}`;
    return baseRequest(url);
}

export function disableProcessAndCancelAllNewTaskAction(processId) {
    let url = `/api/disable-process-and cancel-all-task-actions/${processId}`;
    return baseRequest(url);
}

export function sendProcessMessage(data) {
    return baseRequest(
        `/api/process/send-message`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function editProcessMessage(data) {
    return baseRequest(
        `/api/process/edit-message`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}