import {withRouter} from "react-router";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, withStyles} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {parseXLS} from "../../services/common";
import UploadFilesField from "../../components/fields/UploadFilesField";
import ActionButton from "../../components/button/ActionButton";
import {withTranslation} from "react-i18next";
import {getSystemChannelsForTable, phonesXLSXRowsToDTOArray} from "../../services/whatsapp";
import AnyField from "../../components/fields/AnyField";
import {sendPhonesToMakeDisposableProcess} from "../../services/processes";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";

export default
@withRouter
@withTranslation()
@connect(state => ({
    token: state.auth.token,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
    showError: infoActions.showError
})
@withStyles(theme => ({
}))
class ImportDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        importFunc: PropTypes.func,
        title: PropTypes.string
    };


    constructor(props) {
        super(props);

        this.state = {
            importFileName: null,
            importPhones: [],
            importError: null,
            message: null,
            selectedChannel: null,
            image: null,
            channels: []
        };

    }

    componentDidMount() {
        getSystemChannelsForTable(
            {status: 'active'}
        ).then(response => {
            if (response.success) {
                this.setState({
                    channels: response.data
                })
            }
        })
    }

    handleChangeFile = (event) => {
        this.setState({
            importPhones: [],
            importFileName: null,
            importError: null
        });

        let file = event.target.files[0];

        parseXLS(file).then((json) => {
            let DTOArray = phonesXLSXRowsToDTOArray(json);
            if (Array.isArray(DTOArray)) {
                this.setState({
                    importPhones: DTOArray,
                    importFileName: file.name
                });
            } else {
                this.setState({
                    importError: DTOArray,
                });
            }
        });
    }

    handleImageChange = (event) => {
        let data = event.target.value;
        let image = data ? data.id : null;

        this.setState({
            image: image,
        });
    };

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    changeMessage = event => {
        this.setState({
            message: event.target.value
        });
    }

    changeChannel = (event) => {
        this.setState({
            selectedChannel: event.target.value
        });
    }

    sendMessageByPhoneInFile = () => {

        const { message, importPhones, selectedChannel, image } = this.state
        const { showInfo, t, showError } = this.props

        this.setState({
            loading: true
        })


        sendPhonesToMakeDisposableProcess(
            {
                'message': message,
                'phones': importPhones,
                'channelId': selectedChannel,
                'image': image
            }
        ).then((response) => {
            if ( response.success ) {
                this.setState({
                    loading: false
                }, () => {
                    showInfo(t('Process created'))
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                })
            } else {
                showError(t('Error occurred during creating process'))
            }
        })

    }

    render() {
        const {importPhones, importFileName, importError, message, channels, selectedChannel} = this.state;
        const {open, title, t, classes} = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <UploadFilesField
                                id="importFile"
                                label={t("File")}
                                value={null}
                                onChange={this.handleChangeFile}
                                notLoad
                            />
                            <UploadFilesField
                                id="uploadImage"
                                label={t("Image")}
                                accept={"image/*"}
                                value={null}
                                onChange={this.handleImageChange}
                                image={true}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {!!importPhones.length &&
                            <p>
                                {t("Filename")}: {importFileName}<br/>
                                {t("Rows will be imported")}: {importPhones.length}
                            </p>
                            }
                            {!!importError &&
                            <p style={{color: 'red'}}>{importError}</p>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={4}>
                                <AutocompleteSelectField
                                    placeholder={t('Auto')}
                                    label={t('Channels')}
                                    value={selectedChannel}
                                    onChange={this.changeChannel}
                                    fullWidth={true}
                                    options={channels.map(channel => ({value: channel.systemChannelId, label: channel.phone}))}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label={t("Message") + ":"}
                                value={message}
                                fullWidth={true}
                                onChange={this.changeMessage}
                                multiline={true}
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        onClick={this.sendMessageByPhoneInFile}
                        disabled={ !importPhones.length || !message || !selectedChannel || !message.length }
                        color="primary"
                        fullWidth={false}>{t("Do import")}
                    </ActionButton>
                    <ActionButton
                        onClick={ this.onClose}
                        color="primary"
                        fullWidth={false}
                    >{t("Close")}
                    </ActionButton>
                </DialogActions>
            </Dialog>
        );

    }
}