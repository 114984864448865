import React from 'react';
import { connect } from 'react-redux';
import infoActions from './info-actions';
import { Snackbar, withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    close: {
        padding:  theme.spacing(0.5),
    },
});

@withStyles(styles)
@connect(
    state => ({
        visible: state.info.visible,
        message: state.info.message,
        error: state.info.error,
    }),
    {
        hide: infoActions.hide,
    }
)
class Info extends React.Component {

    handleClose = () => {
        this.props.hide();
    };

    render() {
        const { visible, message, error, classes } = this.props;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={visible}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    className: classNames({[classes.error]: error})
                }}
                message={<span id="message-id">{message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

export default Info;

