import baseRequest from "./baseReuqest"
import * as queryString from "query-string-object";

export const navLinks = [
    { path: "/tasks", text: "Задачи"}
];

export function getTasks(page, per_page, order, order_by) {
    let url = '/api/task';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function loadTask(id) {
    let url = `/api/task/${id}`;
    return baseRequest(url);
}

export function getTaskActions(page, per_page, order, order_by, id) {
    let url = `/api/task/actions`;
    const q = queryString.stringify({page, per_page, order, order_by, id});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}