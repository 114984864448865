import React from 'react';
import {KeyboardTimePicker} from "@material-ui/pickers";
import * as PropTypes from 'prop-types';
import AnyField from "./AnyField";
import {withTranslation} from "react-i18next";

export default
@withTranslation()
class TimeField extends React.Component
{
    static propTypes = {
        value: PropTypes.any,
        initialFocusedDate: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        name: PropTypes.any,
    }

    handleChange = time => {
        const { onChange, name } = this.props;
        if (onChange) {
            onChange(time, name);
        }
    }

    render() {
        const { t, tReady, onChange, ...other } = this.props;

        return (
            <KeyboardTimePicker
                mask="__:__"
                placeholder="00:00"
                clearable
                ampm={false}
                autoOk
                {...other}
                onChange={this.handleChange}
                TextFieldComponent={AnyField}
                invalidDateMessage={t('Invalid Time Format')}
                cancelLabel={t('Cancel')}
                clearLabel={t('Clear')}
                todayLabel={t('Today')}
            />
        )
    }
}
