import React from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import availableLanguages, {getLanguageTitle} from "../../available-languages";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyle = makeStyles(theme => ({
    button: {
        minHeight: 0,
        height: '100%',
        maxHeight: 30,
        minWidth: 0,
        padding: '3px 0 3px 3px',

        '&:hover': {
            backgroundColor: theme.palette.grey.A200,
        },
    },
    arrow: {
        color: '#ffffff',
    },
    languageTitle: {
        marginLeft: theme.spacing(1),
    },
    currentLanguage: {
        maxHeight: '100%',
        height: '100%',
    },
    buttonLabel: {
        height: '100%',
    }
}));

function LanguageSwitcher(props) {
    const popupState = usePopupState({ variant: 'popover', popupId: 'language-switcher'});
    const { i18n } = useTranslation();
    const classes = useStyle();

    const handleClick = event => {
        const lng = event.currentTarget.getAttribute('itemid');
        i18n.changeLanguage(lng);
        popupState.close();
        window.location.reload();
    }

    const currentLanguage = i18n.languages && i18n.languages.length ? i18n.languages[0] : i18n.language;

    return <div className={props.className}>
        <Button {...bindTrigger(popupState)} className={classes.button} classes={{label: classes.buttonLabel}}>
            <img src={`/img/flags/${currentLanguage}.png`} alt={currentLanguage} className={classes.currentLanguage}/>
            <ArrowDropDownIcon fontSize="small" className={classes.arrow}/>
        </Button>
        <Menu {...bindMenu(popupState)}>
            {availableLanguages.map(lng =>
                <MenuItem key={lng} onClick={handleClick} itemID={lng}>
                    <img key={lng} src={`/img/flags/${lng}.png`} alt={lng}/>
                    <span className={classes.languageTitle}>{getLanguageTitle(lng)}</span>
                </MenuItem>
            )}
        </Menu>
    </div>
}

export default React.memo(LanguageSwitcher);