import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Grid, MenuItem,
    Typography
} from '@material-ui/core';
import {
    saveProcess,
    navLinks,
    loadProcess,
    getResultDataForProcessFromErp,
    disableProcessAndCancelAllNewTaskAction, sendProcessMessage, editProcessMessage
} from "../../services/processes"
import {loadTemplates} from "../../services/ems";
import {getSegments} from "../../services/segments";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import DateField from "../../components/fields/DateField";
import AnyField from "../../components/fields/AnyField";
import moment from "moment";
import _ from "lodash";
import TimeField from "../../components/fields/TimeField";
import CheckboxField from "../../components/fields/CheckboxField";
import {
    getExecutorsTypesNames,
    getExecutorsTypesProperties,
    loadExecutor
} from "../../services/executors";
import {getSystemChannelsForTable, loadChannel} from "../../services/whatsapp";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import produce from "immer";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {loadToState} from "../../utils/helpers";

const emptyProcess = {
    id: null,
    name: null,
    segment: {
        id: null,
        name: null,
    },
    segmentId: null,
    filterParams: null,
    enable: false,
    dateCreated: null,
    executor: {
        id: null,
        executorType: null,
        properties: {},
    },
    executorData: {},
    scheduleMainStart: null,
    scheduleMainStop: null,
    scheduleDailyStart: null,
    scheduleDailyStop: null,
    timezone: null,
    disposable: false,
    forceSendMessage: false,
    reusable: false,
    lastExecute: null,
    erpCreator: {},
    canBeEdited: true,
    channel: {
        systemChannelId: null,
    },
    selectedChannel: null,
}

export default @withRouter
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    container: {
        width: 700,
    },
    item: {
        padding: 5,
    },
})
class ViewPage extends React.Component {

    state = {
        process: {
            ...emptyProcess,
        },
        processOriginalData: {
            ...emptyProcess
        },
        executors: {
            properties: null,
            names: null,
            selectChildren: [],
            selectedExecutorPropsFields: []
        },
        segments: [],
        emailTemplates: [],
        selectedValue: '',
        templatesScr: '',
        notFound: false,
        errorMessages: [],
        loading: null,
        editing: true,
        checkRules: {
            regexp: {
                timezone: {
                    process: /^[-+\d:]*$/,
                    finish: /^\+\d\d:\d\d$/,
                    finishErrorMessage: <p key={"timezone"}>{this.props.t("Timezone no in right format")}</p>
                }
            }
        },
        resultFromErpDataElements: [],
        sendMessageForm: {
            formOpen: false,
            data: {
                phone: null
            }
        },
        editMessageForm: {
            formOpen: false,
            data: {
                message: null,
                additionalMessages: []
            }
        },
        customFilter: true,
        channel: {
            systemChannelId: null,
        },
        selectedChannel: null,
        channels: []
    };

    componentDidMount() {

        loadTemplates().then(response => {
            if(response.success) {
                if(response.data.status === 'success'){
                this.setState(produce(draft => {
                    draft.emailTemplates = response.data.data.records;
                    draft.selectedValue = response.data.data.records.[0].template_uid;
                }))
                }
            }
        })

        loadToState(
            this,
            getSegments(),
            'segments',
            (data) => Object.values(data),
        )

        const {id, view, edit} = this.props.match.params;
        const editing = !view && edit;

        this.setState({
            editing: editing,
        })

        getSystemChannelsForTable(
            {status: 'active'}
        ).then(response => {
            if (response.success) {
                this.setState({
                    channels: response.data
                })
            }
        })

        if (id) {
            this.setState({
                loading: true,
            }, () => {
                loadProcess(id).then(
                    (response) => {
                        console.log('check response', response.data)
                        if (response.success) {

                            let data = _.clone(response.data);
                            data.executor = {
                                ...emptyProcess.executor,
                                id: response.data.executor,
                            }

                            this.setState({
                                customFilter: !response.data.segment,
                                process: {
                                    ...emptyProcess,
                                    ...data,
                                    segmentId: response.data.segment ? response.data.segment.id : null,
                                },
                                processOriginalData: {
                                    ...emptyProcess,
                                    ...data,
                                },
                                loading: false,
                            }, () => {
                                loadExecutor(this.state.process.executor.id).then((response) => {
                                    const {process, processOriginalData, editMessageForm} = this.state;

                                    if (response.success) {
                                        this.setState({
                                            process: {
                                                ...process,
                                                executor: {
                                                    ...process.executor,
                                                    id: response.data.id,
                                                    executorType: response.data.type,
                                                    properties: response.data.params
                                                }
                                            },
                                            processOriginalData: {
                                                ...processOriginalData,
                                                executor: {
                                                    ...process.executor,
                                                    id: response.data.id,
                                                    executorType: response.data.type,
                                                    properties: response.data.params
                                                }
                                            },
                                            editMessageForm: {
                                                ...editMessageForm,
                                                data: {
                                                    ...editMessageForm.data,
                                                    additionalMessages: (response.data.params.additional_messages) ? response.data.params.additional_messages : []
                                                }
                                            }
                                        }, () => {
                                            this.renderExecutorParametersFields(this.state.process.executor.executorType);
                                        })
                                    }
                                })
                            })
                        } else if (response.error && response.error.code === 404) {
                            this.setState({
                                errorMessages: this.state.errorMessages.concat([this.props.t('Process not found')])
                            })
                        }
                    }
                );
            })
        }

        Promise.all([getExecutorsTypesProperties(), getExecutorsTypesNames()]).then(responses => {
            if (responses[0].success && responses[1].success)
                this.setState({
                    executors: {
                        ...this.state.executors,
                        properties: responses[0].data,
                        names: responses[1].data
                    }
                }, () => {
                    const {executors} = this.state;
                    let selectChildren = [];

                    _.forEach(this.state.executors.names, (item) => {
                        selectChildren.push(
                            <MenuItem key={item.id} value={item.id}>
                                {item.text}
                            </MenuItem>
                        );
                    })

                    if (selectChildren.length > 0) {
                        this.setState({
                            executors: {
                                ...executors,
                                selectChildren: selectChildren
                            }
                        })
                    }
                })
        })
    }

    saveProcess = () => {
        const {checkRules, process, segmentId, filterParams} = this.state;

        let canSave = true;

        this.setState({
            errorMessages: [],
        }, () => {
            const {errorMessages} = this.state;

            /* check regexp finish rules by fields */
            _.forEach(checkRules.regexp, (rule, field) => {
                if (
                    rule?.finish &&
                    !rule?.finish.test(process[field])
                ) {
                    this.setState({
                        errorMessages: errorMessages.concat([rule.finishErrorMessage])
                    })

                    canSave = false;
                }
            })

            if (!canSave)
                return;

            const updatedProcess = {
                ...process,
                selectedChannel: process.channel.systemChannelId,
                ...filterParams,
                ...segmentId,
            };

            saveProcess(updatedProcess)
                .then((response) => {
                    if (response && response.success) {
                        const lastRecord = response?.data?.data?.records?.[0];
                        // const lastSegmentId = response?.data?.segment;

                        if (lastRecord) {
                            this.setState(produce(draft => {
                                draft.selectedValue = lastRecord.template_uid
                            }))
                        }

                        this.props.history.push('/process/view/' + response.data.id);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error('Failed to save process: ', error);
                });
        })
    }

    editProcess = () => {
        this.setState({
            editing: true,
        })
        this.props.history.push('/process/edit/' + this.state.process.id);
    }

    handleSegmentChange = event => {
        const selectedSegment = this.state.segments.find(
            segment => segment.id === event.target.value
        );
        const filterParamsId = selectedSegment ? selectedSegment.filterParams : '';

        console.log('selectedSegment', filterParamsId);
        this.setState({
            process: {
                ...this.state.process,
                filterParamsId: selectedSegment.filterParams,
                filterParams: null,
                segmentId: event.target.value,
                segment: selectedSegment,
            }
        });
    };

    handleTemplateView = event => {
        const{selectedValue} = this.state;
        const selectedTemplate = this.state.emailTemplates.find(
            emailTemplates => emailTemplates.template_uid === this.state.selectedValue
        );

        if (selectedTemplate) {
            window.open(selectedTemplate.preview, "_blank");
        }

        this.setState({
            process: {
                ...this.state.process,
                Screen: selectedValue,
                preview: selectedValue,
            }
        });
    };

    back = () => {
        const {process, editing, processOriginalData} = this.state;

        if (editing && process.id) {
            this.setState({
                editing: !editing,
                process: processOriginalData,
            }, () => {
                this.props.history.push('/process/view/' + process.id)
            })
        } else {
            this.props.history.push('/process/');
        }
    }

    handleProcessChange = prop => event => {
        const {checkRules} = this.state;
        const inputValue = event.target.value.replace(/^\s+/, '');
        const formattedInputValue = inputValue.replace(/\s{2,}/g, ' ');

        if (
            checkRules.regexp[prop] &&
            checkRules.regexp[prop]?.process
        ) {
            if (!checkRules.regexp[prop].process.test(event.target.value))
                return;
        }

        if (inputValue.length > 0 && /^\s/.test(event.target.value)) {
            return;
        }

        if (formattedInputValue.length > 45) {
            return;
        }

        this.setState({
            process: {
                ...this.state.process,
                [prop]: formattedInputValue,
            }
        });
    }

    handleCheckboxChange = prop => event => {
        const {checkRules} = this.state;

        if (
            checkRules.regexp[prop] &&
            checkRules.regexp[prop]?.process
        ) {
            if (!checkRules.regexp[prop].process.test(event.target.value))
                return;
        }

        this.setState({
            process: {
                ...this.state.process,
                [prop]: event.target.value,
            }
        });
    }

    handleProcessAnyField = prop => event => {
        this.setState({
            process: {
                ...this.state.process,
                [prop]: event.target.value,
            }
        });
    }


    handleProcessDateChange = prop => date => {
        this.setState({
            process: {
                ...this.state.process,
                [prop]: date,
            }
        });
    }

    handleProcessChannelChange = prop => event => {
        loadChannel(event.target.value).then(
            (response) => {
                if (response.success) {
                    this.setState({
                        process: {
                            ...this.state.process,
                            [prop]: response.data,
                            // selectedChannel: process.channel.systemChannelId,
                            selectedChannel: event.target.value
                        },

                    });
                }
            })
    }

    handleExecutorPropertiesChange = prop => event => {
        this.setState({
            selectedValue: event.target.value,
            process: {
                ...this.state.process,
                executor: {
                    ...this.state.process.executor,
                    properties: {
                        ...this.state.process.executor.properties,
                        [prop]: event.target.value
                    }
                },
            }
        });
    }

    handleProcessExecutorChange = prop => event => {
        // const { executors } = this.state;

        let emptyProps = {};
        // _.forEach(executors.properties[event.target.value].properties, (propType, propName) => {
        //     emptyProps[propName] = 'default';
        // });

        this.setState({
            process: {
                ...this.state.process,
                executor: {
                    ...this.state.process.executor,
                    executorType: event.target.value,
                    properties: emptyProps
                },
                executors: {
                    ...this.state.executors,
                    selectedExecutorPropsFields: []
                }
            }
        }, () => {
                this.renderExecutorParametersFields(event.target.value);
        });
    }

    renderExecutorParametersFields = (executorType) => {
        const {executors, emailTemplates} = this.state;

        if (executors?.properties[executorType]?.properties) {
            let executorProperties = [];

            for (let propName in executors.properties[executorType].properties) {
                let propType = executors.properties[executorType].properties[propName];

                const isDropDown = propType === 'dropdown';

                executorProperties.push({
                    propName: propName,
                    multiline: propType === 'text',
                    helperText: (executors.properties[executorType]?.propertiesHelperText[propName]) ? executors.properties[executorType].propertiesHelperText[propName] : null,
                    type:  isDropDown ? 'dropdown' : (propType === 'integer') ? 'number' : 'text',
                    children: isDropDown ? emailTemplates.map(template =>
                        <MenuItem key={template.template_uid} value={template.template_uid}>
                            {template.name}
                        </MenuItem>)
                        : undefined,
                })
            }

            this.setState({
                executors: {
                    ...executors,
                    selectedExecutorPropsFields: executorProperties
                }
            })
        }
    }

    handleProcessTimeChange = prop => time => {
        this.setState({
            process: {
                ...this.state.process,
                [prop]: time,
            }
        });
    }

    getResultDataFromErp = () => {
        const {process} = this.state
        const {showError, t} = this.props

        getResultDataForProcessFromErp(process.id).then(response => {
            if (response.success) {
                let resultFromErpDataElements = [];

                if (response.data.length > 0) {
                    resultFromErpDataElements.push(
                        <p><b>{t('Result from ERP')} - {t('Total')}: {response.data.length}:</b></p>
                    )

                    _.forEach(response.data, (i) => {
                        if (!!i.phone)
                            resultFromErpDataElements.push(
                                <p><b>{t('phone')} {i.phone}</b>: {JSON.stringify(i)}</p>
                            )
                    })
                }

                if (resultFromErpDataElements.length > 0)
                    this.setState({
                        resultFromErpDataElements: resultFromErpDataElements
                    })
                else
                    showError(t('Can not get result from erp for process'))
            }
        })
    }

    handleDisableProcessAndCancelAllNewTaskAction = () => {
        const {process} = this.state
        const {showInfo, showError, t} = this.props

        disableProcessAndCancelAllNewTaskAction(process.id).then(response => {
            if (response.success)
                showInfo(t('Done'))
            else
                showError(t('Error occurred') + `: ${response?.error?.message}`)
        })
    }

    toggleCustomFilter = () => {
        const segmentId = this.state.process.segment ? this.state.process.segment.id : null;
        const filterParams = this.state.process.filterParams ? this.state.process.filterParams : null;
        this.setState(produce(draft => {
            draft.customFilter = !draft.customFilter;
            if (!draft.customFilter) {
                draft.process.filterParams = null;
                draft.process.segmentId = segmentId;
            } else {
                draft.process.filterParams = filterParams;
                draft.process.segmentId = null;
                if(draft.process.segment) draft.process.segment.id = null;
            }
        }))
    }

    handleFilterChange = (event) => {
        const value = event.target.value;
        this.setState(produce(draft => {
            draft.process.filterParams = value;
        }));
        this.forceUpdate();
    }

    // ----------------------------- send process message code section ------------------------

    triggerProcessSendMessage = () => {
        const {sendMessageForm} = this.state

        this.setState({
            sendMessageForm: {
                ...sendMessageForm,
                formOpen: !sendMessageForm.formOpen
            }
        })
    }

    handleProcessSendMessageFormDataChange = prop => event => {
        const {sendMessageForm} = this.state

        this.setState({
            sendMessageForm: {
                ...sendMessageForm,
                data: {
                    ...sendMessageForm.data,
                    [prop]: event.target.value
                }
            }
        })
    }

    sendProcessMessage = () => {
        const {sendMessageForm, process} = this.state
        const {showError, showInfo, t} = this.props

        sendProcessMessage({
            phone: sendMessageForm.data.phone,
            id: process.id
        }).then((response) => {
            if (response.success) {
                this.triggerProcessSendMessage()
                showInfo(t('Message sent'));
            } else {
                showError(response.error.message);
            }
        })
    }

    // ----------------------------- edit process message code section ------------------------

    triggerFormProcessEditMessage = () => {
        const {editMessageForm, processOriginalData} = this.state

        if (!processOriginalData?.message)
            return

        if (editMessageForm.message)
            this.setState({
                editMessageForm: {
                    ...editMessageForm,
                    formOpen: !editMessageForm.formOpen,
                }
            })
        else
            this.setState({
                editMessageForm: {
                    ...editMessageForm,
                    formOpen: !editMessageForm.formOpen,
                    data: {
                        message: processOriginalData.message,
                        additionalMessages: (processOriginalData.executor.properties.additional_messages) ? processOriginalData.executor.properties.additional_messages : []
                    }
                }
            }, () => {
                console.log(this.state.editMessageForm)
            })
    }

    handleProcessEditMessageFormDataChange = prop => event => {
        const {editMessageForm} = this.state

        this.setState({
            editMessageForm: {
                ...editMessageForm,
                data: {
                    ...editMessageForm.data,
                    [prop]: event.target.value
                }
            }
        })
    }

    editProcessMessage = () => {
        const {editMessageForm, process, processOriginalData} = this.state
        const {showError, showInfo, t} = this.props

        let additionalMessagesLocal = [];
        if (editMessageForm.data.additionalMessages.length > 0)
            _.forEach(editMessageForm.data.additionalMessages, (message) => {
                if (message.trim().length > 0)
                    additionalMessagesLocal.push(message)
            })

        editProcessMessage({
            message: editMessageForm.data.message,
            additionalMessages: (additionalMessagesLocal.length > 0) ? additionalMessagesLocal : [],
            id: process.id
        }).then((response) => {
            if (response.success) {
                this.setState({
                    processOriginalData: {
                        ...processOriginalData,
                        message: editMessageForm.data.message
                    },
                }, () => {
                    this.triggerFormProcessEditMessage()
                    showInfo(t('Message edited'))
                })
            } else {
                showError(response.error.message);
            }
        })
    }

    // --------------------------------- additional process messages -------------------------

    addProcessMessage = () => {
        const {editMessageForm} = this.state

        let additionalMessagesLocal = editMessageForm.data.additionalMessages
        additionalMessagesLocal.push('')

        this.setState({
            editMessageForm: {
                ...editMessageForm,
                data: {
                    ...editMessageForm.data,
                    additionalMessages: additionalMessagesLocal
                }
            }
        })
    }

    handleProcessEditAdditionalMessageFormDataChange = idx => event => {
        const {editMessageForm} = this.state

        let additionalMessagesLocal = editMessageForm.data.additionalMessages
        additionalMessagesLocal[idx] = event.target.value

        this.setState({
            editMessageForm: {
                ...editMessageForm,
                data: {
                    ...editMessageForm.data,
                    additionalMessages: additionalMessagesLocal
                }
            }
        })
    }

    render() {
        const {classes, t} = this.props;
        const {
            process, notFound, processOriginalData, errorMessages, editing,
            executors, resultFromErpDataElements, sendMessageForm,
            channels, customFilter, segments
        } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t('Process')}/>}>
                    <ShowField
                        label={t("Name")}
                        value={(processOriginalData?.name) ? processOriginalData.name : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Enable")}
                        value={(processOriginalData?.enable) ? t('Yes') : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Reusable")}
                        value={(processOriginalData?.reusable) ? t('Yes') : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Disposable")}
                        value={(processOriginalData?.disposable) ? t('Yes') : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />

                    <ShowField
                        label={t("Force Send Message")}
                        value={(processOriginalData?.forceSendMessage) ? t('Yes') : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Date create")}
                        value={(processOriginalData?.dateCreated) ? moment(processOriginalData?.dateCreated).format('DD.MM.YYYY') : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Process last execute date")}
                        value={(processOriginalData?.lastExecute) ? moment(processOriginalData?.lastExecute).format('DD.MM.YYYY HH:mm') : t('No')}
                        visible={!!processOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Process erp creator id")}
                        value={(processOriginalData?.erpCreator?.id) ? processOriginalData?.erpCreator?.id : t('No')}
                        visible={!!processOriginalData?.erpCreator?.id}
                        fullWidth
                    />
                    <ShowField
                        label={t("Process erp creator id")}
                        value={(processOriginalData?.erpCreator?.username) ? processOriginalData?.erpCreator?.username : t('No')}
                        visible={!!processOriginalData?.erpCreator?.username}
                        fullWidth
                    />
                    {
                        (processOriginalData?.message) ?
                            <p dangerouslySetInnerHTML={{__html: processOriginalData?.message.replace(/\n/g, '<br />')}}/>
                            : null
                    }
                    <br/>
                    <br/>
                    <ActionButton
                        visible={editing}
                        disabled={
                            (customFilter && (process.filterParams === null || process.filterParams === '')) || (!customFilter && process.segmentId === null)
                        }
                        onClick={this.saveProcess}
                    >{t("Save process")}
                    </ActionButton>
                    {(process.canBeEdited) ?
                        <ActionButton
                            visible={!editing && process.canBeEdited}
                            onClick={this.editProcess}>{t("Edit process")}
                        </ActionButton> :
                        <p>{t('This type of process can not be edited with CRN web interface')}</p>
                    }
                    <ActionButton
                        visible={!!process.id}
                        onClick={this.getResultDataFromErp}>{t("Get result data from erp")}
                    </ActionButton>
                    <ActionButton
                        visible={!!process.id}
                        onClick={this.handleDisableProcessAndCancelAllNewTaskAction}>
                        {(process.enable) ? t("Disable process and cancel all task action in status 'new'") : t("Cancel all task action in status 'new'")}
                    </ActionButton>
                    <ActionButton
                        visible={!!process.id}
                        onClick={this.triggerProcessSendMessage}
                    >
                        {t("Open form for send message of process")}
                    </ActionButton>
                    {sendMessageForm.formOpen ?
                        <Grid>
                            <AnyField
                                id="phone"
                                label={t("Phone")}
                                value={sendMessageForm.data.phone}
                                onChange={this.handleProcessSendMessageFormDataChange('phone')}
                                fullWidth
                                required={true}
                                error={!sendMessageForm.data.phone}
                            />
                            <ActionButton
                                visible={!!process.id}
                                onClick={this.sendProcessMessage}
                                disabled={!sendMessageForm.data.phone}
                            >
                                {t("Send message")}
                            </ActionButton>
                        </Grid>
                        : null
                    }
                    <ActionButton
                        onClick={this.back}>{t("Back")}
                    </ActionButton>
                </LeftBar>
                <Content title={t('Process')} notFoundContent={notFound}>
                    <Typography component={"div"} children={errorMessages} color={"error"}/>
                    <Grid container>
                        <Grid item xs={6} className={classes.item}>
                            <AnyField
                                id="processName"
                                label={t("Process name")}
                                value={process.name}
                                onChange={this.handleProcessChange('name')}
                                placeholder={t("Enter no more than 45 characters")}
                                fullWidth
                                required={true}
                                error={!process.name}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                            <AnyField
                                id="timezone"
                                label={(t("Timezone") + " (" + t("Example") + ": +04:00)")}
                                value={process.timezone}
                                onChange={this.handleProcessChange('timezone')}
                                fullWidth
                                required={true}
                                error={!process.timezone}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.item}>
                            <CheckboxField
                                label={t("Enable")}
                                value={process.enable}
                                onChange={this.handleCheckboxChange("enable")}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.item}>
                            {!customFilter ? (
                                <>
                                <SelectFromItemsField
                                    items={segments}
                                    textField="text"
                                    name="segment.id"
                                    fullWidth
                                    required={true}
                                    value={process.segment ? process.segment.id : null}
                                    onChange={this.handleSegmentChange}
                                    disabled={!editing}
                                />
                                <p>{process.segment ? process.segment.filterParams : null}</p>
                                </>
                            ) : (
                                <AnyField
                                    id="filterParams"
                                    label={t("Filter parameters")}
                                    value={process.filterParams}
                                    // onChange={this.handleProcessAnyField('filterParams')}
                                    onChange={this.handleFilterChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    disabled={!editing}
                                    required={true}
                                    error={!process.filterParams}
                                />
                                )}
                        </Grid>
                        <Grid item xs={3}>
                            <ActionButton
                                onClick={this.toggleCustomFilter}
                                disabled={!editing}
                            >
                                {!customFilter && process.filterParams === null ? t("Use your filter") : t("Use segment filtering")}
                            </ActionButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} className={classes.item}>
                            <CheckboxField
                                label={t("Disposable")}
                                value={process.disposable}
                                onChange={this.handleCheckboxChange("disposable")}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                            <CheckboxField
                                label={t("Reusable")}
                                value={process.reusable}
                                onChange={this.handleCheckboxChange("reusable")}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                            <CheckboxField
                                label={t("Force Send Message")}
                                value={process.forceSendMessage}
                                onChange={this.handleCheckboxChange("forceSendMessage")}
                                disabled={!editing}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={12} item className={classes.item}>
                            <Typography variant={"h6"}>{t("Schedule settings")}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <DateField
                                label={t('Schedule main start')}
                                value={process.scheduleMainStart}
                                onChange={this.handleProcessDateChange('scheduleMainStart')}
                                fullWidth
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <DateField
                                label={t('Schedule main stop')}
                                value={process.scheduleMainStop}
                                onChange={this.handleProcessDateChange('scheduleMainStop')}
                                fullWidth
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TimeField
                                label={t('Schedule daily start')}
                                value={process.scheduleDailyStart}
                                onChange={this.handleProcessTimeChange('scheduleDailyStart')}
                                fullWidth
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TimeField
                                label={t('Schedule daily stop')}
                                value={process.scheduleDailyStop}
                                onChange={this.handleProcessTimeChange('scheduleDailyStop')}
                                fullWidth
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                placeholder={t('Auto')}
                                label={t('Channel')}
                                value={process.channel.systemChannelId}
                                onChange={this.handleProcessChannelChange('channel')}
                                fullWidth={true}
                                required={true}
                                options={channels.map(channel => ({value: channel.systemChannelId, label: channel.phone}))}
                                disabled={!editing}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} item className={classes.item}>
                            <Typography variant={"h6"}>{t("Executor")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="executor"
                                label={t("Executor")}
                                value={process.executor.executorType}
                                onChange={this.handleProcessExecutorChange('executor')}
                                fullWidth
                                required
                                error={!process.executor}
                                disabled={!editing}
                                select
                                children={executors.selectChildren}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {executors.selectedExecutorPropsFields.map(prop => {
                                return <AnyField
                                    key={prop.propName}
                                    label={t(prop.propName)}
                                    value={process.executor.properties[prop.propName]}
                                    onChange={this.handleExecutorPropertiesChange(prop.propName)}
                                    fullWidth
                                    multiline={prop.multiline}
                                    rows={1}
                                    required={true}
                                    error={!process.executor.properties[prop.propName]}
                                    disabled={!editing}
                                    select={Boolean(prop.children)}
                                    children={prop.children}
                                    helperText={prop.helperText}
                                    type={prop.type}
                                />
                            })}
                        </Grid>
                        <Grid children={resultFromErpDataElements}/>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item xs={6}>
                                {executors.selectedExecutorPropsFields.map(prop => {
                                    return <ActionButton
                                    key={prop.propName}
                                    visible={Boolean(prop.children)}
                                    onClick={this.handleTemplateView}
                                    fullWidth={false}
                                    disabled={!editing}
                                >
                                    {t("View template")}
                                </ActionButton>})}
                            </Grid>
                                {/*{editMessageForm.formOpen ?*/}
                                {/*    <Grid container>*/}
                                {/*        <Grid item xs={12}>*/}
                                {/*            <AnyField*/}
                                {/*                id="edit_message_form_message"*/}
                                {/*                label={t("Message")}*/}
                                {/*                value={editMessageForm.data.message}*/}
                                {/*                onChange={this.handleProcessEditMessageFormDataChange('message')}*/}
                                {/*                fullWidth*/}
                                {/*                required={true}*/}
                                {/*                multiline*/}
                                {/*                rows={6}*/}
                                {/*                error={!editMessageForm.data.message}*/}
                                {/*            />*/}
                                {/*            {editMessageForm.data.additionalMessages.map((message, idx) => {*/}
                                {/*                return <AnyField*/}
                                {/*                    key={`additional_message_${idx}`}*/}
                                {/*                    label={t("Message")}*/}
                                {/*                    value={message}*/}
                                {/*                    onChange={this.handleProcessEditAdditionalMessageFormDataChange(idx)}*/}
                                {/*                    fullWidth*/}
                                {/*                    multiline*/}
                                {/*                    rows={6}*/}
                                {/*                />*/}
                                {/*            })}*/}
                                {/*        </Grid>*/}
                                {/*        <Grid item xs={3}>*/}
                                {/*            <ActionButton*/}
                                {/*                visible={!!process.id}*/}
                                {/*                onClick={this.editProcessMessage}*/}
                                {/*                disabled={!editMessageForm.data.message}*/}
                                {/*                fullWidth={false}*/}
                                {/*            >*/}
                                {/*                {t("Edit message")}*/}
                                {/*            </ActionButton>*/}
                                {/*        </Grid>*/}
                                {/*        <Grid item xs={3}>*/}
                                {/*            <ActionButton*/}
                                {/*                onClick={this.addProcessMessage}*/}
                                {/*                fullWidth={false}*/}
                                {/*            >*/}
                                {/*                {t("Add message")}*/}
                                {/*            </ActionButton>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*    : (!processOriginalData?.message) ?*/}
                                {/*        <Typography variant={"body1"}>{t("Can not be edited")}</Typography>*/}
                                {/*        : null*/}
                                {/*}*/}
                            </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
