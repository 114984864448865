import React from 'react';
import { Provider } from "react-redux";
import getStore from "./store";
import {CssBaseline} from "@material-ui/core";
import App from "./App";
import {Router} from "react-router-dom";
import {history} from "./history";
import {withTranslation} from "react-i18next";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ThemeProvider} from '@material-ui/styles';
import {mainTheme} from "./styles";

@withTranslation()
class Root extends React.Component {
    render() {
        return <ThemeProvider theme={mainTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={this.props.i18n.language}>
                <Provider store={getStore()}>
                    <Router history={history} >
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline/>
                        <App/>
                    </Router>
                </Provider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    }

}

export default Root;
