import { handleActions } from 'redux-actions';
import infoActions from './info-actions';
import getStore from "../../store";

const timeout = 3000;
let timer = null;

function clearTimer() {
    if (timer) {
        clearTimeout(timer);
        timer = null;
    }
}

export default handleActions({
    [infoActions.show]: (state, action) => {
        clearTimer();
        timer = setTimeout(() => { getStore().dispatch(infoActions.hide()) }, timeout);
        return {...state, visible: true, message: action.payload, error: false };
    },

    [infoActions.showError]: (state, action) => {
        clearTimer();
        timer = setTimeout(() => { getStore().dispatch(infoActions.hide()) }, timeout);
        return {...state, visible: true, message: action.payload, error: true };
    },

    [infoActions.hide]: (state, action) => {
        clearTimer();
        return {...state, visible: false };
    },
},{
    visible: false,
    message: '',
})