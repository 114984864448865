import React, {useEffect, useState} from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "./GreenApiPage";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {useTranslation} from "react-i18next";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {parseXLS} from "../../services/common";
import {phonesXLSXRowsToDTOArray} from "../../services/whatsapp";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";
import {Dialog, DialogActions, DialogTitle, Grid} from "@material-ui/core";
import {sendPhonesToMakeDisposableProcess} from "../../services/processes";
import {getAccessToken} from "../../services/baseReuqest";

export default function SendMessagePage() {
    const { t } = useTranslation();
    const [importPhones, setImportPhones] = useState([]);
    const [importFileName, setImportFileName] = useState(null);
    const [importError, setImportError] = useState(null);
    const [message, setMessage] = useState('');
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [instances, setInstances] = useState([]);
    const [error, setError] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);

    useEffect(() => {
        fetch('/api/instance/authorized', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            }
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data && data.success) {
                setInstances(data.data);
            }
        })
    }, []);

    const changeInstance = (event) => {
        setSelectedInstance(event.target.value);
    }

    const changeMessage = (event) => {
        setMessage(event.target.value);
    }

    const handleChangeFile = (event) => {
        setImportPhones([]);
        setImportFileName(null);
        setImportError(null);

        let file = event.target.files[0];

        parseXLS(file).then((json) => {
            let DTOArray = phonesXLSXRowsToDTOArray(json);
            if (Array.isArray(DTOArray)) {
                setImportPhones(DTOArray);
                setImportFileName(file.name);
            } else {
                setImportError(DTOArray);
                setError(true);

                setTimeout(() => {
                    setError(false);
                }, 3000)
            }
        });
    }

    const sendMessageByPhoneInFile = () => {

        sendPhonesToMakeDisposableProcess(
            {
                'message': message,
                'phones': importPhones,
                'vendor': 'green_api',
                'greenApiInstanceId': selectedInstance
            }
        ).then((response) => {
            if ( response.success ) {
                successResponse();
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
                console.log('response', response)
            }
        })
    }

    const successResponse = () => {
        setSuccessDialog(true);
        setTimeout(() => {
            setSuccessDialog(false);
        }, 2000)
    }

    return (
        <>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}/>
            <Content title={t("Send messages")}>
                <Grid container>
                    <Grid item xs={2}>
                        <UploadFilesField
                            id="importFile"
                            label={t("Send by xlsx file")}
                            value={null}
                            onChange={handleChangeFile}
                            notLoad
                            style={{ width: "20%!important" }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        {
                            importFileName !== null && importPhones.length > 0
                                ?
                                <div style={{ width: "100%"}}>
                                    <p>Имя файла: {importFileName}</p>
                                    <p>Строки, которые будут взяты в работу: {importPhones.length}</p>
                                </div>
                                :
                                <div></div>
                        }
                    </Grid>
                    <AutocompleteSelectField
                        placeholder={t('Auto')}
                        label={t('Instances')}
                        value={selectedInstance}
                        onChange={changeInstance}
                        options={instances.map(instance => ({value: instance.id, label: instance.name}))}
                        style={{width:'20%'}}
                    />
                    <AnyField
                        label={t("Message") + ":"}
                        value={message}
                        fullWidth={true}
                        onChange={changeMessage}
                        multiline={true}
                        rows={3}
                    />
                </Grid>
                <DialogActions>
                    <ActionButton
                        onClick={sendMessageByPhoneInFile}
                        disabled={ !importPhones.length || !selectedInstance || !message.length }
                        color="primary"
                        fullWidth={false}>{t("Do import")}
                    </ActionButton>
                </DialogActions>

                <Dialog open={error} onClose={() => setError(false)}>
                    <DialogTitle id="dialog-title">{ importError }</DialogTitle>
                </Dialog>

                <Dialog open={successDialog} style={{ bottom: 30 }}>
                    <DialogTitle id="dialog-title">{t("Success")}</DialogTitle>
                </Dialog>
            </Content>
        </>
    )
}