import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import reducer from './reducer'
import {
    loadToken
} from "./services/localStorage";

let store = null;

export default function getStore() {
    if (store) return store;

    store = createStore(
        reducer,
        {
            auth: {
                isLoginPending: false,
                isLoginSuccess: false,
                loginError: null,
                user: null,
                isSuperAdmin: false,
                token: loadToken(),
                roles: [],
            },
        },
        composeWithDevTools(applyMiddleware(
            thunk
        ))
    );

    return store;
}
