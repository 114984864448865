import React from 'react';
import {Button, Grid, withStyles} from "@material-ui/core";
import * as PropTypes from 'prop-types';
import cn from 'classnames';
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

export default
@withStyles(theme => ({
    root: {
        textAlign: 'center',
    },
    margin: {
        marginTop:  theme.spacing(1),
    },
    marginLabeledFields: {
        marginTop:  theme.spacing(3),
    },
    loading: {
        marginLeft:  theme.spacing(1),
    },
}))
@connect(state => ({
    backgroundProcess: state.backgroundProcess.visible
}))
class ActionButton extends React.PureComponent
{
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        visible: PropTypes.bool,
        fullWidth: PropTypes.bool,
        href: PropTypes.string,
        to: PropTypes.string,
        color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'default']),
        noMargin: PropTypes.bool,
        marginLabeledFields: PropTypes.bool,
        inGridItem: PropTypes.bool,
        inGridItem12: PropTypes.bool,
        processing: PropTypes.bool,
        itemID: PropTypes.any,
        targetBlank: PropTypes.bool,
        variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    };

    static defaultProps = {
        disabled: false,
        visible: true,
        fullWidth: true,
        noMargin: false,
        processing: false,
        inGridItem: false,
        inGridItem12: false,
        targetBlank: false,
        color: 'secondary',
        variant: 'contained',
        size: 'small',
    };

    render() {
        const {
            classes,
            className,
            onClick,
            children,
            disabled,
            visible,
            fullWidth,
            href,
            noMargin,
            marginLabeledFields,
            inGridItem,
            inGridItem12,
            processing,
            backgroundProcess,
            dispatch,
            targetBlank,
            to,
            ...other
        } = this.props;

        if (visible) {
            const button = <Button
                className={cn(classes.root, {[classes.margin]: !noMargin, [classes.marginLabeledFields]: marginLabeledFields}, className)}
                fullWidth={fullWidth}
                onClick={onClick}
                disabled={disabled || (!onClick && !href && !to) || backgroundProcess}
                href={href}
                to={to}
                component={href ? 'a' : to ? Link : 'button'}
                target={targetBlank ? '_blank' : null}
                {...other}
            >{children}{processing && <CircularProgress className={classes.loading} size={20}/>}</Button>;

            if (inGridItem) {
                return <Grid item >{button}</Grid>;
            }

            if (inGridItem12) {
                return <Grid item xs={12}>{button}</Grid>;
            }

            return button;
        }

        return null;
    }
}
