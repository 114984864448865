import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {getGreenApiData} from "../../services/greenApi";
import {navLinks} from "./GreenApiPage";

const columnData = [
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
    { id: 'dateCreated', label: 'Date created', dateFormat: "DD.MM.YYYY" },
    { id: 'actualSendDate', label: 'Date send', dateFormat: "DD.MM.YYYY HH:mm:ss" },
    { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
    {
        id: 'additionalParameters',
        label: 'Parameters',
        numeric: false,
        processValue: (data) => {
            let returnData = [];

            if (_.size(data) > 0) {
                _.forEach(data, (el, i) => {
                    returnData.push( <p>{`${i} : ${el}`}</p> );
                })
            }

            return returnData;
        }
    },
    { id: 'messageSendStatus', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'messageType', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'messageConnectInitiator', numeric: false, disablePadding: false, label: 'Initiator' },
];

const getTableData = (...params) => {
    return getGreenApiData(...params);
}

export default function MessagesPage() {
    const { t } = useTranslation();

    return (
        <>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}/>
            <Content title={t("Messages")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    selector={false}
                />
            </Content>
        </>
    )
}