import baseRequest from "./baseReuqest";

export function login(username, password) {

    return baseRequest('/api/login_check', {
        method: 'POST',
        body: JSON.stringify({
            username: username,
            password: password,
        }),
        headers:  new Headers({
            'Content-Type': 'application/json',
        }),
    }, false);
}
