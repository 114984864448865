export function createChangeHandle(context, stateField = null) {
    let handler;
    if (stateField) {
        handler =  function (event) {
            this.setState({
                [stateField]: {
                    ...this.state[stateField],
                    [event.target.name]: event.target.value,
                }
            });
        }
    } else {
        handler = function(event) {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    }

    return handler.bind(context);
}

export function loadToState(componentContext, requestPromise, prop, dataMapper = null) {
    requestPromise.then(response => {
        if (response.success) {
            componentContext.setState({
                [prop]: dataMapper ? dataMapper(response.data) : response.data,
            })
        }
    })
}