import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Grid,
    Typography
} from '@material-ui/core';
import {
    loadSegment,
    saveSegment,
    navLinks
} from "../../services/segments"
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import AnyField from "../../components/fields/AnyField";
import moment from "moment";
import _ from "lodash";
import CheckboxField from "../../components/fields/CheckboxField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import produce from "immer";
import {getBxLinkFunnelStages, getBxLinkFunnels} from "../../services/bx-link";
import {loadToState} from "../../utils/helpers";

const emptySegment = {
    id: null,
    name: null,
    filterParams: null,
    mappingFunnel: {
        funnelId: null,
        stageNew: null,
        stageInProgress: null,
        stageNotDialing: null,
        stageSuccess: null,
        stageFalse: null,
    },
    enable: false,
    dateCreated: null,
    timezone: null,
    erpCreator: {},
    canBeEdited: true,
}

export default @withRouter
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    container: {
        width: 700,
    },
    item: {
        padding: 5,
    },
})
class ViewPage extends React.Component {

    state = {
        segment: {
            ...emptySegment
        },
        segmentOriginalData: {
            ...emptySegment
        },
        funnels: [],
        stages: [],
        notFound: false,
        errorMessages: [],
        loading: null,
        editing: true,
        checkRules: {
            regexp: {
                // timezone: {
                //     segment: /^[-+\d:]*$/,
                //     finish: /^\+\d\d:\d\d$/,
                //     finishErrorMessage: <p key={"timezone"}>{this.props.t("Timezone no in right format")}</p>
                // }
            }
        },
        resultFromErpDataElements: []
    };

    componentDidMount() {
        loadToState(
            this,
            getBxLinkFunnels(),
            'funnels',
            (data) => Object.values(data)
        )
        const {id, view, edit} = this.props.match.params;
        const editing = !view && edit;

        this.setState({
            editing: editing,
        })

        if (id) {
            this.setState({
                loading: true,
            }, () => {
                loadSegment(id).then(
                    (response) => {
                        if (response.success) {
                            console.log('loadSegment', response.data)
                            let data = _.clone(response.data);

                            this.setState({
                                segment: {
                                    ...emptySegment,
                                    ...data,
                                },
                                segmentOriginalData: {
                                    ...emptySegment,
                                    ...data
                                },
                                loading: false,
                            })

                            const funnelId = data.mappingFunnel?.funnelId;
                            if (funnelId) {
                                getBxLinkFunnelStages(funnelId).then(response => {
                                    if (response.success) {
                                        this.setState(() => ({
                                            stages: Object.values(response.data),
                                        }))
                                    }
                                })
                            }
                        } else if (response.error && response.error.code === 404) {
                            this.setState({
                                errorMessages: this.state.errorMessages.concat([this.props.t('Process not found')])
                            })
                        }
                    }
                );
            })
        }

        Promise.all([]).then(responses => {
        })
    }

    saveSegment = () => {
        const {checkRules, segment} = this.state;

        let canSave = true;

        this.setState({
            errorMessages: []
        }, () => {
            const {errorMessages} = this.state;

            /* check regexp finish rules by fields */
            _.forEach(checkRules.regexp, (rule, field) => {
                if (
                    rule?.finish &&
                    !rule?.finish.test(segment[field])
                ) {
                    this.setState({
                        errorMessages: errorMessages.concat([rule.finishErrorMessage])
                    })

                    canSave = false;
                }
            })

            if (!canSave)
                return;

            saveSegment(segment)
                .then((response) => {
                    if (response.success) {
                        this.props.history.push('/segment/view/' + response.data.id);
                        window.location.reload();
                    }
                });
        })
    }
    handleSegmentFunnelChange = event => {
        const { value } = event.target;

        this.setState(produce(draft => {
            draft.segment.mappingFunnel.funnelId = value;
            draft.segment.mappingFunnel.stageNew = null;
            draft.segment.mappingFunnel.stageInProgress = null;
            draft.segment.mappingFunnel.stageNotDialing = null;
            draft.segment.mappingFunnel.stageSuccess = null;
            draft.segment.mappingFunnel.stageFalse = null;
        }))

        getBxLinkFunnelStages(value).then(response => {
            if (response.success) {
                this.setState(produce(draft => {
                    draft.stages = Object.values(response.data);
                }))
            }
        })
    }
    handleSegmentStageChange = event => {
        const { value, name } = event.target;

        this.setState(produce(draft => {
            draft.segment.mappingFunnel[name] = value;
        }))
    }

    editSegment = () => {
        this.setState({
            editing: true,
        })
        this.props.history.push('/segment/edit/' + this.state.segment.id);
    }

    back = () => {
        const {segment, editing, segmentOriginalData} = this.state;

        if (editing && segment.id) {
            this.setState({
                editing: !editing,
                segment: segmentOriginalData,
            }, () => {
                this.props.history.push('/segment/view/' + segment.id)
            })
        } else {
            this.props.history.push('/segment/');
        }
    }

    handleSegmentChange = prop => event => {
        const inputValue = event.target.value.replace(/^\s+/, '');
        const formattedInputValue = inputValue.replace(/\s{2,}/g, ' ');

        if (inputValue.length > 0 && /^\s/.test(event.target.value)) {
            return;
        }

        if (formattedInputValue.length > 45) {
            return;
        }

        this.setState({
            segment: {
                ...this.state.segment,
                [prop]: formattedInputValue,
            }
        });
    }

    handleCheckboxChange = prop => event => {
        const {checkRules} = this.state;

        if (
            checkRules.regexp[prop] &&
            checkRules.regexp[prop]?.segment
        ) {
            if (!checkRules.regexp[prop].segment.test(event.target.value))
                return;
        }

        this.setState({
            segment: {
                ...this.state.segment,
                [prop]: event.target.value,
            }
        });
    }

    handleSegmentAnyField = prop => event => {
        this.setState({
            segment: {
                ...this.state.segment,
                [prop]: event.target.value,
            }
        });
    }

    // --------------------------------- additional segment messages -------------------------

    render() {
        const {classes, t} = this.props;
        const {
            segment, notFound, segmentOriginalData, funnels, stages, errorMessages, editing,
        } = this.state;


        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t('Segment')}/>}>
                    <ShowField
                        label={t("Name")}
                        value={(segmentOriginalData?.name) ? segmentOriginalData.name : t('No')}
                        visible={!!segmentOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Enable")}
                        value={(segmentOriginalData?.enable) ? t('Yes') : t('No')}
                        visible={!!segmentOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Date create")}
                        value={(segmentOriginalData?.dateCreated) ? moment(segmentOriginalData?.dateCreated).format('DD.MM.YYYY') : t('No')}
                        visible={!!segmentOriginalData}
                        fullWidth
                    />
                    <ShowField
                        label={t("Process erp creator id")}
                        value={(segmentOriginalData?.erpCreator?.id) ? segmentOriginalData?.erpCreator?.id : t('No')}
                        visible={!!segmentOriginalData?.erpCreator?.id}
                        fullWidth
                    />
                    <ShowField
                        label={t("Process erp creator id")}
                        value={(segmentOriginalData?.erpCreator?.username) ? segmentOriginalData?.erpCreator?.username : t('No')}
                        visible={!!segmentOriginalData?.erpCreator?.username}
                        fullWidth
                    />
                    {
                        (segmentOriginalData?.message) ?
                            <p dangerouslySetInnerHTML={{__html: segmentOriginalData?.message.replace(/\n/g, '<br />')}}/>
                            : null
                    }
                    <br/>
                    <br/>
                    <ActionButton
                        visible={editing}
                        onClick={this.saveSegment}
                        disabled={!segment.name || !segment.filterParams || !segment.mappingFunnel.funnelId || !segment.mappingFunnel.stageNew || !segment.mappingFunnel.stageFalse || !segment.mappingFunnel.stageSuccess || !segment.mappingFunnel.stageInProgress || !segment.mappingFunnel.stageNotDialing}>{t("Save segment")}
                    </ActionButton>
                    {(segment.canBeEdited) ?
                        <ActionButton
                            visible={!editing && segment.canBeEdited}
                            onClick={this.editSegment}>{t("Edit segment")}
                        </ActionButton> :
                        <p>{t('This type of segment can not be edited with CRN web interface')}</p>
                    }
                    <ActionButton
                        onClick={this.back}>{t("Back")}
                    </ActionButton>
                </LeftBar>
                <Content title={t('Segment')} notFoundContent={notFound}>
                    <Typography component={"div"} children={errorMessages} color={"error"}/>
                    <Grid container>
                        <Grid item xs={6} className={classes.item}>
                            <AnyField
                                id="segmentName"
                                label={t("Segment name")}
                                value={segment.name}
                                onChange={this.handleSegmentChange('name')}
                                placeholder={t("Enter no more than 45 characters")}
                                fullWidth
                                required
                                error={!segment.name}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.item}>
                            <CheckboxField
                                name="enable"
                                label={t("Enable")}
                                value={segment.enable}
                                onChange={this.handleCheckboxChange('enable')}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.item}>
                            <AnyField
                                id="filterParams"
                                label={t("Filter parameters")}
                                value={segment.filterParams}
                                onChange={this.handleSegmentAnyField('filterParams')}
                                fullWidth
                                multiline
                                rows={4}
                                required
                                error={!segment.filterParams}
                                disabled={!editing}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={12} item>
                            <Typography variant={"subtitle2"}>{t("Stage Mapping")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <SelectFromItemsField
                                items={funnels}
                                textField="text"
                                name="mappingFunnel.funnelId"
                                label={t("Funnel")}
                                fullWidth
                                required
                                error={!segment.mappingFunnel.funnelId}
                                value={segment.mappingFunnel.funnelId}
                                onChange={this.handleSegmentFunnelChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <SelectFromItemsField
                                items={stages}
                                textField="text"
                                name="stageNew"
                                label={t("Stage New")}
                                value={segment.mappingFunnel.stageNew}
                                onChange={this.handleSegmentStageChange}
                                fullWidth
                                required
                                error={!segment.mappingFunnel.stageNew}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <SelectFromItemsField
                                items={stages}
                                textField="text"
                                name="stageInProgress"
                                label={t("Stage In Progress")}
                                value={segment.mappingFunnel.stageInProgress}
                                onChange={this.handleSegmentStageChange}
                                fullWidth
                                required
                                error={!segment.mappingFunnel.stageInProgress}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <SelectFromItemsField
                                items={stages}
                                textField="text"
                                name="stageNotDialing"
                                label={t("Stage Not Dialing")}
                                value={segment.mappingFunnel.stageNotDialing}
                                onChange={this.handleSegmentStageChange}
                                fullWidth
                                required
                                error={!segment.mappingFunnel.stageNotDialing}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <SelectFromItemsField
                                items={stages}
                                textField="text"
                                name="stageSuccess"
                                label={t("Stage Success")}
                                value={segment.mappingFunnel.stageSuccess}
                                onChange={this.handleSegmentStageChange}
                                fullWidth
                                required
                                error={!segment.mappingFunnel.stageSuccess}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <SelectFromItemsField
                                items={stages}
                                textField="text"
                                name="stageFalse"
                                label={t("Stage False")}
                                value={segment.mappingFunnel.stageFalse}
                                onChange={this.handleSegmentStageChange}
                                fullWidth
                                required
                                error={!segment.mappingFunnel.stageFalse}
                                disabled={!editing}
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
