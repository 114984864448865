import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import * as PropTypes from 'prop-types';
import cn from 'classnames';

const styles = theme => ({
    title: {
        color: theme.palette.primary.main
    },
});

class PageTitle extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const { classes, className } = this.props;

        return (
            <Typography variant="h6" className={cn(classes.title, className)}>{this.props.children}</Typography>
        )
    }
}

export default withStyles(styles)(PageTitle);