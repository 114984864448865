import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import confirmDialogActions from './confirmDialog-acions';
import { connect } from 'react-redux';
import AnyField from "../fields/AnyField";
import {withTranslation} from "react-i18next";

@withTranslation()

class ConfirmDialog extends React.Component
{
    state = {
        comment: null,
    };

    handleClose = ok => (confirmAll) => {
        this.props.hide();

        if (this.props.onClose) {
            this.props.onClose(ok, this.state.comment, confirmAll === true || false);
        }
    };

    handleChangeComment = event => {
        this.setState({
            comment: event.target.value,
        });
    };

    handleCloseEveryone = ok => () => {
        this.handleClose(ok)(true);
    }

    render() {
        const { title, open, message, showComment, showYesAll, t } = this.props;
        const { comment } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{title || 'Внимание'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description" variant="subtitle1">{message}</DialogContentText>
                    {showComment ?
                        <AnyField
                            label="Комментарий"
                            value={comment}
                            onChange={this.handleChangeComment}
                            multiline
                            rows={4}
                            fullWidth
                        />
                        : null
                    }
                </DialogContent>
                <DialogActions>
                    {
                        showYesAll
                            ? <Button onClick={this.handleCloseEveryone(true)} color="primary">{t('YesEveryone')}</Button>
                            : null
                    }
                    <Button onClick={this.handleClose(true)} color="primary">{t('Yes')}</Button>
                    <Button onClick={this.handleClose(false)} color="primary" autoFocus>{t('No')}</Button>
                </DialogActions>
            </Dialog>

        );
    }
}

const mapStateToProps = state => ({
    open: state.confirm.open,
    message: state.confirm.message,
    title: state.confirm.title,
    onClose: state.confirm.onClose,
    showComment: state.confirm.showComment,
    showYesAll: state.confirm.showYesAll
});

export default connect(mapStateToProps, {hide: confirmDialogActions.hide})(ConfirmDialog);