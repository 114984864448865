import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getSegmentsForTableView, navLinks} from "../../services/segments";
import _ from "lodash";
import {loadToState} from "../../utils/helpers";
import {getBxLinkFunnels} from "../../services/bx-link";

export default
@withRouter
@withTranslation()
class ListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false,
        funnels: [],
    };

    columnData = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Process name' },
        { id: 'dateCreated', label: 'Date created', dateFormat: "DD.MM.YYYY" },
        { id: 'funnelIdName', numeric: false, disablePadding: false, label: 'Mapping Funnel'},
        { id: 'stageNewName', numeric: false, disablePadding: false, label: 'Stage New' },
        { id: 'stageInProgressName', numeric: false, disablePadding: false, label: 'In Work' },
        { id: 'stageNotDialingName', numeric: false, disablePadding: false, label: 'Stage Not Dialing' },
        { id: 'stageFalseName', numeric: false, disablePadding: false, label: 'Stage False' },
        { id: 'stageSuccessName', numeric: false, disablePadding: false, label: 'Went Into a Deal',
            processValue: (data) => {
                let returnData = '';
                let requiredFields = 'funnelId';
                if (_.size(data) > 0) {
                    _.forEach(data, (el, i) => {
                        if (requiredFields.includes(i) ) {
                            returnData.push(<p>{`${i} : ${el}`}</p>);
                        }
                    })
                }

                return returnData;
            }
            },
        // {
        //     id: 'erpCreator',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'ERP creator',
        //     processValue: (data) => {
        //         let returnData = [];
        //
        //         if (_.size(data) > 0) {
        //             _.forEach(data, (el, i) => {
        //                 returnData.push( <p>{`${i} : ${el}`}</p> );
        //             })
        //         }
        //
        //         return returnData;
        //     }
        // },
    ];

    componentDidMount() {
        loadToState(
            this,
            getBxLinkFunnels(),
            'funnels',
            (data) => Object.values(data)
        )
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/segment/edit/0`);
    };

    getTableData = (...params) => {
        getSegmentsForTableView(...params)
            .then((response) => {
                if (response.success) {
                    console.log(response.data);}
            });
        return getSegmentsForTableView(...params);
    };

    render() {
        const { t } = this.props;
        const { refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd}>{t("Add")}</ActionButton>
                </LeftBar>
                <Content title={t("Segments")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/segment/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
