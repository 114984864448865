import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getExecutors, navLinks} from "../../services/executors";
import _ from "lodash";

export default
@withRouter
@withTranslation()
class ListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false
    };

    columnData = [
        { id: 'dateCreated', label: 'Schedule main start', dateFormat: "DD.MM.YYYY" },
        {
            id: 'params',
            label: 'Parameters',
            numeric: false,
            processValue: (data) => {
                let returnData = [];

                if (_.size(data) > 0) {
                    _.forEach(data, (i, el) => {
                        returnData.push( <p>{`${i} : ${el}`}</p> );
                    })
                }

                return returnData;
            }
        },
        { id: 'type', numeric: false, disablePadding: false, label: 'Type' }
    ];

    getTableData = (...params) => {
        return getExecutors(...params);
    };

    render() {
        const { t } = this.props;
        const { refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <p>{t('List of executors')}</p>
                </LeftBar>
                <Content title={t("Executors")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/executor/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
