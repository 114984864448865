import React from 'react';
import * as PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    withStyles,
    ListItemIcon,
    Button, CircularProgress
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import classNames from "classnames";
import _ from 'lodash';
import {getDownloadLink, getImageDownloadLink, uploadFile} from "../../services/storage";
import filesize from 'filesize';
import infoActions from "../info/info-actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const styles = theme => ({
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    fileIcon: {
        marginRight: 0,
    },
    input: {
        display: 'none',
    },
    limit: {
        display: 'inline-block',
        color: theme.palette.text.secondary,
        paddingLeft:  theme.spacing(1),
    },
    image: {
        width: 100,
        height: 100,
        objectFit: 'cover'
    }
});

export default
@connect(null,{
    showError: infoActions.showError,
})
@withStyles(styles)
@withTranslation()
class UploadFilesField extends React.Component
{
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        label: PropTypes.string,
        accept: PropTypes.string,
        multiple: PropTypes.bool,
        onChange: PropTypes.func,
        className: PropTypes.string,
        limit: PropTypes.number,
        fullWidth: PropTypes.bool,
        value: PropTypes.any,
        buttonLabel: PropTypes.any,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        image: PropTypes.bool,
        notLoad: PropTypes.bool
    };

    static defaultProps = {
        multiple: false,
        fullWidth: true,
        buttonLabel: 'Upload',
        disabled: false,
        readOnly: false,
        image: false,
        notLoad: false
    };

    constructor(props) {
        UploadFilesField.defaultProps.buttonLabel = props.t('Upload');
        super(props);

        this.state = {
            oldValue: props.value,
            loadedFiles: this.processValue(props.value),
            loading: 0,
        };
    }

    processValue(value) {
        if (_.isArray(value)) {
            return value.map(item => { const processItem = this.processValue(item); return processItem.length ? processItem[0] : null }).filter(item => item !== null);
        }

        if (_.isObject(value) && value.id && value.name) {
            return [value];
        }

        return [];
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.state.oldValue) {
            this.setState({
                oldValue: nextProps.value,
                loadedFiles: this.processValue(nextProps.value),

            })
        }
    }

    handleFileChange = event => {
        if (this.props.notLoad) {
            if (this.props.onChange) {
                this.props.onChange(event);
            }
            return;
        }

        let files = event.target.files;

        if (this.props.limit) {
            files = _.filter(files, file => file.size/1024/1024 <= this.props.limit);
        }

        if (files.length) {
            this.loadFiles(files);
        }
    };

    loadFiles = (files) => {
        if (this.props.multiple) {
            this.setState({
                loading: files.length,
            });
            for (let i = 0; i < files.length; i++) {
                uploadFile(files[i])
                    .then(response => {
                        this.setState(state => ({
                            loading: state.loading - 1,
                        }));

                        if (response.success) {
                            this.setState(state => {
                                const loadedFiles = [
                                    ...state.loadedFiles,
                                    response.data,
                                ];

                                if (this.props.onChange) {
                                    this.props.onChange({ target: { name: this.props.name, value: loadedFiles } });
                                }

                                return {
                                    loadedFiles,
                                };
                            });
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    })
            }

        } else {
            this.setState({
                loading: 1,
            });

            uploadFile(files[0])
                .then(response => {
                    this.setState({
                        loading: 0,
                    });
                    if (response.success) {
                        this.setState(state => {
                            const loadedFiles = [
                                ...state.loadedFiles,
                                response.data,
                            ];

                            if (this.props.onChange) {
                                this.props.onChange({ target: { name: this.props.name, value: response.data } });
                            }

                            return {
                                loadedFiles,
                            };
                        });
                    } else {
                        this.props.showError(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleDelete = idx => () => {
        let loadedFiles = [...this.state.loadedFiles];

        loadedFiles.splice(idx,1);

        this.setState({
            loadedFiles,
        });
        //TODO: Delete files on server

        if (this.props.onChange) {
            this.props.onChange({ target: { name: this.props.name, value: this.props.multiple ? loadedFiles : null } });
        }
    };

    render() {
        const { id, label, fullWidth, classes, className, accept, multiple, buttonLabel, limit, disabled, readOnly, image, t } = this.props;
        const { loadedFiles, loading } = this.state;
        const inputId = id + '-input';

        return (
            <FormControl
                id={id}
                fullWidth={fullWidth}
                className={classNames(classes.root,{[classes.withLabel]: label}, className)}
            >
                <InputLabel shrink variant="outlined">{label}</InputLabel>
                <List disablePadding dense>
                    {loadedFiles.map((file, idx) =>
                        <ListItem key={idx} disableGutters>
                            <ListItemIcon className={classes.fileIcon}>
                                {image ?
                                    <img src={getImageDownloadLink(file.id)} className={classes.image} alt={file.name}/>
                                    :
                                    <FileIcon fontSize="small"/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={<a href={getDownloadLink(file.id)}>{file.name}</a>}
                                secondary={file.size ? filesize(file.size) : null}
                            />
                            {!disabled && !readOnly ?
                                <ListItemSecondaryAction>
                                    <IconButton aria-label={t("Delete")} onClick={this.handleDelete(idx)}
                                                disabled={disabled}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                                : null
                            }
                        </ListItem>
                    )}
                    {readOnly && loadedFiles.length === 0 ?
                        <ListItem/>
                        : null
                    }
                </List>
                { loading > 0 ? <div><CircularProgress size={20}/></div> : null }
                {!disabled && !readOnly && (multiple || loadedFiles.length === 0) ?
                    <div>
                        <input
                            accept={accept}
                            className={classes.input}
                            id={inputId}
                            multiple={multiple}
                            type="file"
                            onChange={this.handleFileChange}
                            value=""
                        />
                        <label htmlFor={inputId}>
                            <Button variant="contained" color="secondary" size="small" component="span" disabled={loading > 0}>{buttonLabel}</Button>
                        </label>
                        {limit ? <span className={classes.limit}>{t('maxFileSize', {limit})}</span> : null}
                    </div>
                    : null
                }
            </FormControl>
        );
    }
}
