import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {
    getSystemChannelsForTable,
    getWhatsappChannelsForTable, initWebhookUrl, resetQueuesUrl, startQueuesUrl,
    navLinks, saveChannel,
    updateWhatsAppChannels, getQueuesLabels
} from "../../services/whatsapp";
import DataTable from "../../components/data-table/DataTable";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import CheckboxField from "../../components/fields/CheckboxField";
import AnyField from "../../components/fields/AnyField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";

export default
@withRouter
@withTranslation()
@connect(null,{
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    not_active: {
        backgroundColor: "#f13636",
    },
    active: {
        backgroundColor: "#40a143",
    }
})
class ChannelsPage extends React.Component {

    state = {
        loading: false,
        channels: {},
        channel: {},
        queues: [],
        dialogEditOpen: false,
    };

    columnData = [
        { id: 'plainId', numeric: false, disableSorting: true, label: 'Phone' },
        { id: 'channelId', numeric: false, disableSorting: true, label: 'Id' },
        { id: 'transport', numeric: false, disableSorting: true, label: 'Transport' },
        { id: 'state', numeric: false, disableSorting: true, label: 'State' }
    ];

    systemChannelsColumnData = [
        { id: 'systemChannelId', numeric: false, disableSorting: true, label: 'Id' },
        { id: 'channelId', numeric: false, disableSorting: true, label: 'Whatsapp id' },
        { id: 'transport', numeric: false, disableSorting: true, label: 'Transport' },
        { id: 'phone', numeric: false, disableSorting: true, label: 'Phone' },
        { id: 'state', numeric: false, disableSorting: true, label: 'State' },
        {
            id: 'dateCreate',
            numeric: false,

            disableSorting: true,
            label: 'Date created',
            processValue: (val) => {
                return moment(val).format('YYYY.MM.DD HH:mm')
            }
        },
        { id: 'queue', numeric: false, disableSorting: true, label: 'Queue' },
        { id: 'minPauseMessage', numeric: false, disableSorting: true, label: 'Min pause message' },
        { id: 'maxPauseMessage', numeric: false, disableSorting: true, label: 'Max pause message' },
        { id: 'countInBatch', numeric: false, disableSorting: true, label: 'Count in batch' },
        { id: 'minPauseBetweenBatch', numeric: false, disableSorting: true, label: 'Min pause between batch' },
        { id: 'maxPauseBetweenBatch', numeric: false, disableSorting: true, label: 'Max pause between batch' },
        { id: 'minPauseReachingLimiter', numeric: false, disableSorting: true, label: 'Min pause reaching Limiter' },
        { id: 'maxPauseReachingLimiter', numeric: false, disableSorting: true, label: 'Max pause reaching Limiter' },
    ];

    rowStyle = (item) => {
        if (item?.stateOriginalValue === 'active')
            return {backgroundColor: '#85c585', paddingLeft: 5};
        else
            return {backgroundColor: '#da8383', paddingLeft: 5};
    }

    getTableData = (...params) => {
        return getWhatsappChannelsForTable(...params);
    };

    getSystemChannelsTableData = (...params) => {
        return getSystemChannelsForTable(...params);
    };


    componentDidMount() {
        getQueuesLabels().then(response => {
            if (response.success) {
                this.setState({
                    queues: response.data
                })
            }
        })
    }

    getFetchedData = (response) => {
        if (response.success) {
            this.setState({
                channels: response.data,
            })
        }
    }

    back = () => {
        this.props.history.push('/whatsapp/');
    }

    updateSystemChannels = () => {
        const { showInfo, t } = this.props;

        updateWhatsAppChannels(this.state.channels).then((response) => {
            if (response.success && response?.data?.message)
                showInfo(response.data.message)
            else
                showInfo(t('Success'))
        })
    }

    handleInitWebhookUrl = () => {
        const { showError, showInfo, t } = this.props

        initWebhookUrl()
            .then(response => {
                if (response.success) {
                    let msg = t('Success') + ( (!!response?.data?.status) ? ` - Response status: ${response?.data?.status}` : '' )
                    showInfo(msg)
                } else {
                    showError(response.error ? response.error.message : t("Can't init webhook url"))
                }
            })
    };

    handleResetQueuesUrl = () => {
        const { showError, showInfo, t } = this.props

        resetQueuesUrl()
            .then(response => {
                if (response.success) {
                    let msg = t('Success') + ( (!!response?.data?.status) ? ` - Response status: ${response?.data?.status}` : '' )
                    showInfo(msg)
                } else {
                    showError(response.error ? response.error.message : t("Can't reset queues"))
                }
            })
    };

    handleStartQueuesUrl = () => {
        const { showError, showInfo, t } = this.props

        startQueuesUrl()
            .then(response => {
                if (response.success) {
                    let msg = t('Success') + ( (!!response?.data?.status) ? ` - Response status: ${response?.data?.status}` : '' )
                    showInfo(msg)
                } else {
                    showError(response.error ? response.error.message : t("Can't reset queues"))
                }
            })
    };

    getUrlPauseExample = () => {
       return 'https://docs.google.com/spreadsheets/d/1hfj_Ol_UC01HbLgCIJoEgKcsMDGuQLyRzl2GeJVRlS4';
    }

    handleChange = (prop) => event => {
        this.setState({
            channel: {
                ...this.state.channel,
                [prop]: event.target.value,
            }
        });
    };

    handleEdit = channel => {
        this.setState({
            channel,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveChannel(this.state.channel)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { t } = this.props;
        const {dialogEditOpen, channel, queues} = this.state;
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <p>{t('List of channels and webhook settings')}</p>
                    <ActionButton onClick={this.back}>{t('Back')}</ActionButton>
                    <ActionButton onClick={this.updateSystemChannels}>{t('Update system channels')}</ActionButton>
                    <ActionButton onClick={this.handleInitWebhookUrl}>{t('Register webhook url')}</ActionButton>
                    <ActionButton onClick={this.handleResetQueuesUrl}>{t('Reset Queues')}</ActionButton>
                    <Typography variant={"h6"}>{t("Limits channels per min")}</Typography>
                    <Typography variant={"body2"}>{t("whatsapp.channel1 = [10message/1min] ")}</Typography>
                    <Typography variant={"body2"}>{t("whatsapp.channel2 = [20message/1min] ")}</Typography>
                    <Typography variant={"body2"}>{t("whatsapp.channel3 = [30message/1min] ")}</Typography>
                    <Typography variant={"body2"}>{t("whatsapp.channel4 = [40message/1min] ")}</Typography>
                    <Typography variant={"body2"}>{t("whatsapp.channel5 = [50message/1min] ")}</Typography>
                    <ActionButton color="primary" href={this.getUrlPauseExample()} target="_blank" >{t('Pause settings examples')}</ActionButton>
                </LeftBar>
                <Content title={t("Whatsapp channels")}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}>{t("Channels from server")}</Typography>
                            <DataTable
                                columnData={this.columnData}
                                dataFunc={this.getTableData}
                                selector={false}
                                pagination={false}
                                rowStyleFunc={this.rowStyle}
                                onDataFetch={this.getFetchedData}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}>{t("System channels")}</Typography>
                            <DataTable
                                columnData={this.systemChannelsColumnData}
                                dataFunc={this.getSystemChannelsTableData}
                                selector={false}
                                pagination={false}
                                rowStyleFunc={this.rowStyle}
                                onClick={this.handleEdit}
                            />
                        </Grid>
                    </Grid>
                </Content>

                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                    fullWidth
                >
                    <DialogTitle id="dialog-title">{t("Channel Card")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Grid item xs={8}>
                                    <AutocompleteSelectField
                                        placeholder={t('Auto')}
                                        label={t('Queues')}
                                        value={(channel?.queue) ? channel.queue : ''}
                                        onChange={this.handleChange('queue')}
                                        fullWidth={true}
                                        options={queues.map(queue => ({value: queue.value, label: queue.name}))}
                                    />
                                </Grid>
                                <AnyField
                                    id="minPauseMessage"
                                    label={t("Min pause message")}
                                    value={(channel?.minPauseMessage) ? channel.minPauseMessage : ''}
                                    onChange={this.handleChange('minPauseMessage')}
                                    visible={true}
                                />
                                <AnyField
                                    id="minPauseBetweenBatch"
                                    label={t("Min pause between batch")}
                                    value={(channel?.minPauseBetweenBatch) ? channel.minPauseBetweenBatch : ''}
                                    onChange={this.handleChange('minPauseBetweenBatch')}
                                    visible={true}
                                />
                                <AnyField
                                    id="minPauseReachingLimiter"
                                    label={t("Min pause reaching Limiter")}
                                    value={(channel?.minPauseReachingLimiter) ? channel.minPauseReachingLimiter : ''}
                                    onChange={this.handleChange('minPauseReachingLimiter')}
                                    visible={true}
                                />
                                <CheckboxField
                                    label={t("Default Use")}
                                    value={channel.default}
                                    onChange={this.handleChange('default')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    id="countInBatch"
                                    label={t("Count in batch")}
                                    value={(channel?.countInBatch) ? channel.countInBatch : ''}
                                    onChange={this.handleChange('countInBatch')}
                                    visible={true}
                                />
                                <AnyField
                                    id="maxPauseMessage"
                                    label={t("Max pause message")}
                                    value={(channel?.maxPauseMessage) ? channel.maxPauseMessage : ''}
                                    onChange={this.handleChange('maxPauseMessage')}
                                    visible={true}
                                />
                                <AnyField
                                    id="maxPauseBetweenBatch"
                                    label={t("Max pause between batch")}
                                    value={(channel?.maxPauseBetweenBatch) ? channel.maxPauseBetweenBatch : ''}
                                    onChange={this.handleChange('maxPauseBetweenBatch')}
                                    visible={true}
                                />
                                <AnyField
                                    id="maxPauseReachingLimiter"
                                    label={t("Max pause reaching Limiter")}
                                    value={(channel?.maxPauseReachingLimiter) ? channel.maxPauseReachingLimiter : ''}
                                    onChange={this.handleChange('maxPauseReachingLimiter')}
                                    visible={true}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
