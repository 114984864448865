import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getWhatsAppMessagesVSVisits, navLinks} from "../../services/report"
import {Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import ActionButton from "../../components/button/ActionButton";
import DateField from "../../components/fields/DateField";
import AnyField from "../../components/fields/AnyField";
import _ from "lodash";
import moment from "moment";

export default
@withRouter
@withTranslation()
class VisitsToWhatsAppCorrelationReportPage extends React.Component {

    state = {
        loading: true,
        tableDataParameters: {
            filterDateStart: null,
            filterDateStop : null,
            daysAfter: 3
        },
        canGetData: false,
        tableRows: [],
        numberOfVisits: 0,
        visitsSumPrice: 0
    };

    getTableData = () => {
        const { tableDataParameters } = this.state

        let paramsForSend = {};
        _.forEach(tableDataParameters, (el, i) => {
            if (el?.format && typeof el.format == 'function')
                paramsForSend[i] = el?.format()
            else
                paramsForSend[i] = el
        })

        getWhatsAppMessagesVSVisits(paramsForSend).then( response => {
            if (response.success) {
                let [tableRows, visitsSumPrice] = [[], 0]

                _.forEach(response.data, (el) => {
                    tableRows.push({
                        messagePhone: el.messagePhone,
                        messageDateCreate: moment(el.messageDateCreated),
                        messageText: el.messageText,
                        visitId: el.visitId,
                        visitDateStart: moment(el.visitDateStart),
                        visitDateCreate: moment(el.visitDateCreate),
                        visitGuestPhone: el.visitGuestPhone,
                        visitGuestName: el.visitGuestName,
                        visitStatus: el.visitStatusText,
                        visitFullPrice: el.visitFullPrice.replace(/,\d+?[^\d]+$/, '').replace(' ', '.')
                    })

                    if (!!el?.visitFullPrice)
                        visitsSumPrice += parseInt( el?.visitFullPrice.replace(/[^\d,.]/g, '') )
                })

                this.setState({
                    tableRows: tableRows,
                    numberOfVisits: tableRows.length,
                    visitsSumPrice: visitsSumPrice
                })
            }
        });
    };

    handleTableDataParametersChange = prop => event => {
        const { tableDataParameters } = this.state

        this.setState({
            tableDataParameters: {
                ...tableDataParameters,
                [prop]: event.target.value
            }
        }, () => { this.canGetData() })
    }

    handleTableDataParametersDateChange = prop => date => {
        const { tableDataParameters } = this.state

        this.setState({
            tableDataParameters: {
                ...tableDataParameters,
                [prop]: date
            }
        }, () => { this.canGetData() })
    }

    canGetData = () => {
        const { tableDataParameters, canGetData } = this.state
        if (
            !!tableDataParameters.filterDateStart &&
            !!tableDataParameters.filterDateStop &&
            !!tableDataParameters.daysAfter
        )
            this.setState({
                canGetData: true
            })
        else if (canGetData)
            this.setState({
                canGetData: false
            })
    }

    render() {
        const { t } = this.props;
        const { loading, tableDataParameters, canGetData, tableRows, numberOfVisits, visitsSumPrice } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <p>{t('Report of whatsapp messages and visits')}</p>
                    <p>{t('Parameters')}</p>
                    <DateField
                        label={t('Date start')}
                        value={tableDataParameters.filterDateStart}
                        onChange={ this.handleTableDataParametersDateChange('filterDateStart') }
                        fullWidth
                        disabled={!loading}
                    />
                    <DateField
                        label={t('Date stop')}
                        value={tableDataParameters.filterDateStop}
                        onChange={ this.handleTableDataParametersDateChange('filterDateStop') }
                        fullWidth
                        disabled={!loading}
                    />
                    <AnyField
                        id="executor"
                        label={t("Days after")}
                        value={tableDataParameters.daysAfter}
                        onChange={this.handleTableDataParametersChange('daysAfter')}
                        fullWidth
                        required
                        error={!tableDataParameters.daysAfter}
                        disabled={!loading}
                    />
                    <ActionButton
                        disabled={!loading || !canGetData}
                        onClick={this.getTableData}>{t("Get data")}
                    </ActionButton>
                </LeftBar>
                <Content title={t("Report")}>
                    { (numberOfVisits > 0) ?
                        <Typography>
                            {t("Number of visits count")}: {numberOfVisits + ' '}
                            {t("from")} {moment(tableDataParameters.filterDateStart).format('DD.MM.Y')  + ' '}
                            {t("to")} {moment(tableDataParameters.filterDateStop).format('DD.MM.Y')  + ' '}
                            {t("Summary price of visits")}: {visitsSumPrice}
                        </Typography>
                        : null
                    }
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("Message phone")}</TableCell>
                                    <TableCell align="right">{t("Message date create")}</TableCell>
                                    <TableCell align="right">{t("Message text")}</TableCell>
                                    <TableCell align="right">{t("Visit guest name")}</TableCell>
                                    <TableCell align="right">{t("Visit id")}</TableCell>
                                    <TableCell align="right">{t("Visit date create")}</TableCell>
                                    <TableCell align="right">{t("Visit date start")}</TableCell>
                                    <TableCell align="right">{t("Visit status")}</TableCell>
                                    <TableCell align="right" sx={{ width:100 }}>{t("Visit full price")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows.map((row) => (
                                    <TableRow
                                        key={row.messagePhone}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.messagePhone}
                                        </TableCell>
                                        <TableCell align="right">{row.messageDateCreate.format('DD.MM.Y HH:mm')}</TableCell>
                                        <TableCell align="right">{row.messageText}</TableCell>
                                        <TableCell align="right">{row.visitGuestName}</TableCell>
                                        <TableCell align="right">{row.visitId}</TableCell>
                                        <TableCell align="right">{row.visitDateCreate.format('DD.MM.Y HH:mm')}</TableCell>
                                        <TableCell align="right">{row.visitDateStart.format('DD.MM.Y HH:mm')}</TableCell>
                                        <TableCell align="right">{row.visitStatus}</TableCell>
                                        <TableCell align="right" sx={{ width:100 }}>{row.visitFullPrice}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Content>
            </React.Fragment>
        );
    }
}
