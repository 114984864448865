import { handleActions } from 'redux-actions';
import confirmDialogActions from './confirmDialog-acions';

export default handleActions({
    [confirmDialogActions.show]: (state, action) => {
        return {
            ...state,
            open: true,
            message: action.payload.message,
            title: action.payload.title,
            onClose: action.payload.onClose,
            showComment: !!action.payload.showComment,
            showYesAll: action.payload.showYesAll,
        };
    },

    [confirmDialogActions.hide]: (state, action) => {
        return {...state, open: false };
    },
},{
    open: false,
    message: null,
    title: null,
    onClose: null,
    showComment: false,
    showYesAll: false,
})