import * as queryString from 'query-string-object';
import baseRequest from "./baseReuqest";

export const navLinks = [
    { path: "/segment", text: "Segments"},
];

export function getSegments(page, per_page, order, order_by) {
    let url = '/api/segment';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getSegmentsForTableView(page, per_page, order, order_by) {
    let url = '/api/segment-for-table-view';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function loadSegment(id) {
    let url = `/api/segment/${id}`;
    return baseRequest(url);
}

export function saveSegment(data) {
    return baseRequest(
        `/api/segment`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}
