import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import ViewPage from "./ViewPage";

class SegmentPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/segment"                component={ListPage}/>
                <Route       path="/segment/:view/:id"            component={ViewPage}/>
                <Route       path="/segment/:edit/:id"            component={ViewPage}/>
            </Switch>
        );
    }
}

export default SegmentPage;