import React from 'react';
import * as PropTypes from 'prop-types';
import AnyField, {propTypes as anyFieldPropTypes} from "./AnyField";
import MenuItem from "@material-ui/core/MenuItem";
import {lighten, withStyles} from "@material-ui/core";

const nullValue = '---NULL---';

export default
@withStyles(theme => ({
    stripedList: {
        '& > li:nth-child(2n)': {
            backgroundColor: lighten(theme.palette.primary.light, 0.8),
        },
    },
}))
class SelectFromItemsField extends React.PureComponent
{
    static propTypes = {
        ...anyFieldPropTypes,
        items: PropTypes.array.isRequired,
        valueField: PropTypes.string,
        textField: PropTypes.string,
        nullable: PropTypes.bool,
        nullableText: PropTypes.string,
        autoSelectFirst: PropTypes.bool,
        customItemLabel: PropTypes.func,
        stripedItems: PropTypes.bool,
    };

    static defaultProps = {
        valueField: 'id',
        textField: 'name',
        nullable: false,
        nullableText: 'Не выбрано',
        autoSelectFirst: false,
    };

    componentDidMount() {
        if (this.props.autoSelectFirst && this.props.items && this.props.items.length && this.props.value === null) {
            this.handleChange({target: {name: this.props.name, value: this.props.items[0][this.props.valueField]}});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.autoSelectFirst && this.props.items && this.props.items !== prevProps.items && this.props.length && this.props.value === null) {
            this.handleChange({target: {name: this.props.name, value: this.props.items[0][this.props.valueField]}});
        }
    }

    handleChange = event => {
        const {nullable, onChange} = this.props;
        let value = event.target.value;
        if (nullable && event.target.value === nullValue) {
            value = null;
        }

        if (onChange) {
            onChange({...event, target: {...event.target, value}});
        }
    };

    renderItemLabel(item) {
        const { textField, customItemLabel, name } = this.props;
        if (customItemLabel) {
            return customItemLabel(item, name);
        }

        return item[textField];
    }

    render() {
        const {
            items,
            valueField,
            textField,
            children,
            onChange,
            value,
            nullable,
            nullableText,
            autoSelectFirst,
            customItemLabel,
            stripedItems,
            SelectProps,
            classes,
            ...otherProps
        } = this.props;

        const {stripedList, ...otherClasses} = classes;

        const addSelectProps = {};
        if (stripedItems) {
            addSelectProps.MenuProps = {
                classes: {
                    list: stripedList,
                },
            }
        }

        return <AnyField
            {...otherProps}
            value={nullable && (value === null || value === undefined) ? nullValue : items && items.length  ? value : ''}
            onChange={this.handleChange}
            select
            nullValue={nullValue}
            SelectProps={{
                ...SelectProps,
                ...addSelectProps,
            }}
            classes={otherClasses}
        >
            {nullable && <MenuItem value={nullValue}><i>{nullableText}</i></MenuItem>}
            {items.map((item, idx) => <MenuItem key={idx} value={item[valueField]}>{this.renderItemLabel(item)}</MenuItem>)}
        </AnyField>;
    }
}

