import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Drawer} from "@material-ui/core";
import * as PropTypes from 'prop-types';

export default
@withStyles(theme => ({
    info: {
        minHeight: 200,
        marginBottom:  theme.spacing(2),
        padding:  theme.spacing(1),
    },
    drawerPaper: {
        position: 'relative',
        width: 300,
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        },
    },
}))
class LeftBar extends React.Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        navigationLinks: PropTypes.element.isRequired,
        hidePrint: PropTypes.bool,
    };

    static defaultProps = {
        hidePrint: false,
    };

    render() {
        const { classes, children, navigationLinks, hidePrint } = this.props;

        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                className={hidePrint ? classes.hidePrint : null}
            >
                <div className={classes.info}>{children}</div>
                {navigationLinks}
            </Drawer>
        );
    }
}
