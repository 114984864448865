import { handleActions } from 'redux-actions';
import messageDialogActions from './messageDialog-acions';

export default handleActions({
    [messageDialogActions.show]: (state, action) => {
        if (action.payload && action.payload.message) {
            return {...state, open: true, message: action.payload.message, title: action.payload.title };
        } else {
            return {...state, open: true, message: action.payload};
        }
    },

    [messageDialogActions.hide]: (state, action) => {
        return {...state, open: false };
    },
},{
    open: false,
    message: null,
    title: null,
})