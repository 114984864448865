import * as queryString from 'query-string-object';
import baseRequest from "./baseReuqest";

export const navLinks = [
    { path: "/whatsapp", text: "Whatsapp messages"},
    { path: "/whatsapp/new", text: "Send messages"},
    { path: "/whatsapp/channels", text: "Channels and webhooks"},
    { path: "/whatsapp/synonyms", text: "Synonyms"},
    { path: "/whatsapp/sending-statistics", text: "Sending statistics"},
];

export function getWhatsappMessages(page, per_page, order, order_by) {
    let url = '/api/whatsapp';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getSendingStatistics() {
    let url = '/api/whatsapp/sending-statistics';
    return baseRequest(url);
}

export function loadMessage(id) {
    let url = `/api/whatsapp/${id}`;
    return baseRequest(url);
}

export function sendWhatsappMessage(data) {
    return baseRequest(
        `/api/whatsapp`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function getWhatsappChannelsForTable(search, page, per_page, order, order_by) {
    let url = '/api/whatsapp/channel';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getSystemChannelsForTable(search, page, per_page, order, order_by) {
    let url = '/api/whatsapp/system/channel';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function updateWhatsAppChannels(data) {
    return baseRequest(
        `/api/whatsapp/channel/update`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function getSynonyms(page, per_page, order, order_by) {
    let url = '/api/whatsapp/channel';
    return baseRequest(url);
}

export function phonesXLSXRowsToDTOArray(data) {
    let keys = {
        phone: 'Телефон',
    };

    let DTOArray = [];
    let error = false;

    data.forEach((row) => {
        let dto = {};
        for (let prop in keys) {
            let XLSXKey = keys[prop];
            if (typeof row[XLSXKey] === 'undefined') {
                error = 'Колонки "' + XLSXKey + '" не существует';
                return false;
            }
            dto[prop] = row[XLSXKey];
        }
        DTOArray.push(dto);
    });

    if (error) {
        return error;
    }

    return DTOArray;
}

export function initWebhookUrl() {
    return baseRequest(`/api/whatsapp/init-webhook`);
}

export function resetQueuesUrl() {
    return baseRequest(`/api/whatsapp/reset-queues`);
}
export function startQueuesUrl() {
    return baseRequest(`/api/whatsapp/start-queues`);
}

export function saveChannel(channel) {
    return baseRequest(`/api/whatsapp/channel/save`, {
        method: 'PUT',
        body: JSON.stringify(channel),
    });
}

export function getQueuesLabels() {
    return baseRequest(`/api/whatsapp/queues-labels`);
}

export function loadChannel(id) {
    let url = `/api/whatsapp/${id}`;
    return baseRequest(url);
}
