import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import PageTitle from "../typography/PageTitle";
import {CircularProgress} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";

export default
@withStyles(theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
        minWidth: 0, // So the Typography noWrap works

        '@media print': {
            backgroundColor: 'transparent',
        },
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        }
    },
}))
@withTranslation()
class Content extends React.Component
{
    static propTypes = {
        children: PropTypes.any.isRequired,
        title: PropTypes.any,
        loading: PropTypes.bool,
        notFoundContent: PropTypes.bool,
        hidePrintTitle: PropTypes.bool,
    };

    static defaultProps = {
        loading: false,
        notFoundContent: false,
    };

    componentDidMount() {
        if (this.props.title) {
            document.title = this.props.title;
        }
    }

    render() {
        const { classes, children, title, loading, notFoundContent, t, hidePrintTitle } = this.props;

        return (
            <div className={classes.content}>
                { title ? <PageTitle className={hidePrintTitle ? classes.hidePrint : null}>{title}</PageTitle> : null }
                {loading ?
                    <CircularProgress size={30}/>
                    : ( notFoundContent ?
                        <Typography>{t('Not found')}</Typography>
                        : children
                    )
                }
            </div>
        )
    }
}
