import React, {useEffect, useState} from "react";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "./GreenApiPage";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {getAllInstances} from "../../services/greenApi";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import ActionButton from "../../components/button/ActionButton";
import {getAccessToken} from "../../services/baseReuqest";
import AnyField from "../../components/fields/AnyField";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    btn: {
        backgroundColor: "#f0f0f0",
        border: "none",
        cursor: "pointer",
        padding: "5px",
        borderRadius: "4px",
        transition: "background-color 0.3s ease",

        "&:hover": {
            backgroundColor: "#d9d9d9",
        },
    }
}))

export default function InstancesPage() {
    const {t} = useTranslation();
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [rowData, setRowData] = useState({});
    const [originalRowData, setOriginalRowData] = useState({});
    const [errorDialog, setErrorDialog] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [newInstance, setNewInstance] = useState({
        phone: '',
        name: '',
        queue: '',
        minPauseMessage: '5',
        maxPauseMessage: '10',
        countInBatch: '5',
        minPauseBatch: '6',
        maxPauseBatch: '18',
        countMessagesPerMinute: '50',
        minPauseLimiter: '300',
        maxPauseLimiter: '500',
        instanceId: '',
        apiTokenInstance: ''
    });
    const [disableBtn, setDisableBtn] = useState(true);

    const columnData = [
        {
            id: 'action', numeric: true, disableSorting: true, label: 'Action',
            processValue: () => {
                return (
                    <>
                        <button className={classes.btn}>{t("Settings")}</button>
                    </>
                );

            },
            onClick: () => {
                // openMenuDialog(val);
            }
        },
        {id: 'phone', numeric: false, disableSorting: true, label: 'Phone'},
        {id: 'name', numeric: false, disableSorting: true, label: 'Name'},
        {id: 'instanceState', numeric: false, disableSorting: true, label: 'State'},
        {
            id: 'dateCreate',
            numeric: false,

            disableSorting: true,
            label: 'Date created',
            processValue: (val) => {
                return moment(val).format('DD.MM.YYYY')
            }
        },
        {id: 'queue', numeric: false, disableSorting: true, label: 'Queue'},
        {id: 'minPauseMessage', numeric: false, disableSorting: true, label: 'Min pause message'},
        {id: 'maxPauseMessage', numeric: false, disableSorting: true, label: 'Max pause message'},
        {id: 'countInBatch', numeric: false, disableSorting: true, label: 'Count in batch'},
        {id: 'minPauseBatch', numeric: false, disableSorting: true, label: 'Min pause between batch'},
        {id: 'maxPauseBatch', numeric: false, disableSorting: true, label: 'Max pause between batch'},
        {id: 'countMessagesPerMinute', numeric: false, disableSorting: true, label: 'Count messages per minute'},
        {id: 'minPauseLimiter', numeric: false, disableSorting: true, label: 'Min pause reaching Limiter'},
        {id: 'maxPauseLimiter', numeric: false, disableSorting: true, label: 'Max pause reaching Limiter'},
    ]

    useEffect(() => {
        if (newInstance.phone.length && newInstance.name.length && newInstance.instanceId.length && newInstance.queue.length &&
            newInstance.minPauseMessage.length && newInstance.maxPauseMessage.length &&
            newInstance.countInBatch.length && newInstance.minPauseBatch.length &&
            newInstance.maxPauseBatch.length && newInstance.countMessagesPerMinute.length &&
            newInstance.minPauseLimiter.length && newInstance.maxPauseLimiter.length &&
            newInstance.apiTokenInstance.length) {
            setDisableBtn(false)
        } else {
            setDisableBtn(true)
        }
    }, [newInstance]);

    const getTableData = () => {
        return getAllInstances('1', '30', 'asc', 'phone');
    }

    const openMenuDialog = (row) => {
        setRowData(row);
        setOriginalRowData(row);
        setIsOpen(!isOpen);
    };

    const handleCloseEditDialog = () => {
        setIsOpenEditDialog(!isOpenEditDialog);
        setRowData(originalRowData);
    }

    const updateStatus = () => {
        fetch(`/api/instance/${rowData.id}/status/update-one`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                const currentRow = data.data.find(item => item.id === rowData.id);
                setRowData(currentRow);
                successResponse();
                setRefreshTable(!refreshTable);
            } else {
                errorOccurred();
            }
        });
    }

    const restartInstance = () => {
        fetch(`/api/instance/${rowData.id}/reboot`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                const currentRow = data.data.find(item => item.id === rowData.id);
                setRowData(currentRow);
                successResponse();
                setRefreshTable(!refreshTable);
            } else {
                errorOccurred();
            }
        });
    }

    const logoutInstance = () => {
        fetch(`/api/instance/${rowData.id}/logout`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                const currentRow = data.data.find(item => item.id === rowData.id);
                setRowData(currentRow);
                successResponse();
                setRefreshTable(!refreshTable);
            } else {
                errorOccurred();
            }
        });
    }

    // const deleteInstance = () => {
    //     fetch(`/api/instance/${rowData.id}/delete`, {
    //         method: "DELETE",
    //         headers: {
    //             "Authorization": `Bearer ${getAccessToken()}`
    //         }
    //     }).then((response) => {
    //         return response.json();
    //     }).then((data) => {
    //         if (data.success) {
    //             successResponse();
    //             setRefreshTable(!refreshTable);
    //         } else {
    //             errorOccurred();
    //         }
    //     });
    // }

    const editInstance = () => {
        const payload = {
            phoneNumber: rowData.phone ?? null,
            name: rowData.name ?? null,
            queue: rowData.queue ?? null,
            minPauseMessage: Number(rowData.minPauseMessage) ?? null,
            maxPauseMessage: Number(rowData.maxPauseMessage) ?? null,
            countInBatch: Number(rowData.countInBatch) ?? null,
            minPauseBetweenBatch: Number(rowData.minPauseBatch) ?? null,
            maxPauseBetweenBatch: Number(rowData.maxPauseBatch) ?? null,
            countMessagesPerMinute: Number(rowData.countMessagesPerMinute) ?? null,
            minPauseReachingLimiter: Number(rowData.minPauseLimiter) ?? null,
            maxPauseReachingLimiter: Number(rowData.maxPauseLimiter) ?? null,
            instanceId: rowData.instanceId ?? null,
            apiTokenInstance: rowData.apiTokenInstance ?? null
        };

        fetch(`/api/instance/${rowData.id}/edit`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            },
            body: JSON.stringify(payload)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                const currentRow = data.data.find(item => item.id === rowData.id);
                setRowData(currentRow);
                successResponse();
                setRefreshTable(!refreshTable);
            } else {
                errorOccurred();
            }
        });
        setIsOpenEditDialog(false);
    }

    const getQR = () => {
        if (rowData.instanceState === "Не авторизован") {
            window.open(`https://qr.green-api.com/waInstance${rowData.instanceId}/${rowData.apiTokenInstance}`, '_blank')
        }
    }

    const handleChange = (prop) => event => {
        setRowData({
            ...rowData,
            [prop]: event.target.value,
        })
    }

    const handleChangeCreate = (prop) => event => {
        setNewInstance({
            ...newInstance,
            [prop]: event.target.value,
        })
    }

    const isOpenCreateDialog = () => {
        setIsOpenCreate(!isOpenCreate);
    }

    const createInstance = () => {
        const payload = {
            phoneNumber: newInstance.phone ?? null,
            name: newInstance.name ?? null,
            queue: newInstance.queue ?? null,
            minPauseMessage: Number(newInstance.minPauseMessage) ?? null,
            maxPauseMessage: Number(newInstance.maxPauseMessage) ?? null,
            countInBatch: Number(newInstance.countInBatch) ?? null,
            minPauseBetweenBatch: Number(newInstance.minPauseBatch) ?? null,
            maxPauseBetweenBatch: Number(newInstance.maxPauseBatch) ?? null,
            countMessagesPerMinute: Number(newInstance.countMessagesPerMinute) ?? null,
            minPauseReachingLimiter: Number(newInstance.minPauseLimiter) ?? null,
            maxPauseReachingLimiter: Number(newInstance.maxPauseLimiter) ?? null,
            instanceId: newInstance.instanceId ?? null,
            apiTokenInstance: newInstance.apiTokenInstance ?? null
        };

        fetch(`/api/instance/create`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            },
            body: JSON.stringify(payload)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                successResponse();
                setIsOpenCreate(false);
                setRefreshTable(!refreshTable);
            } else {
                errorOccurred();
            }
        });
    }

    const updateAllInstances = () => {
        fetch('/api/instance/status/update-all', {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${getAccessToken()}`
            }
        }).then(response => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                setRefreshTable(!refreshTable);
            }
        })
    }

    const errorOccurred = () => {
        setErrorDialog(true);
        setTimeout(() => {
            setErrorDialog(false);
        }, 2000)
    }

    const successResponse = () => {
        setSuccessDialog(true);
        setTimeout(() => {
            setSuccessDialog(false);
        }, 2000)
    }

    return (
        <>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={isOpenCreateDialog}>{t("Create instance")}</ActionButton>
                <ActionButton onClick={updateAllInstances}>{t("Update statuses all instances")}</ActionButton>
            </LeftBar>
            <Content title={t("Instances")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    selector={false}
                    onClick={openMenuDialog}
                    refresh={refreshTable}
                    pagination={false}
                />
            </Content>

            <Dialog open={isOpen} onClose={openMenuDialog}>
                <DialogTitle id="dialog-title">{t("Settings")} {rowData.phone}</DialogTitle>
                <div style={{padding: "0 24px 24px"}}>
                    <div>Актуальный статус: { rowData.instanceState }</div>
                    <ActionButton onClick={updateStatus}>{t("Update status")}</ActionButton>
                    <ActionButton onClick={restartInstance}>{t("Reboot")}</ActionButton>
                    <ActionButton onClick={logoutInstance} disabled={rowData.instanceState === "Не авторизован"}>{t("Logout")}</ActionButton>
                    <ActionButton onClick={handleCloseEditDialog}>{t("Edit")}</ActionButton>
                    {/*<ActionButton onClick={deleteInstance}>{t("Delete")}</ActionButton>*/}
                    <Tooltip title="Кнопка неактивна если пользователь авторизован">
                        <div>
                            <ActionButton onClick={getQR} disabled={rowData.instanceState !== "Не авторизован"}>
                                {t("Get QR")}
                            </ActionButton>
                        </div>
                    </Tooltip>
                </div>
            </Dialog>

            <Dialog
                open={isOpenEditDialog}
                onClose={handleCloseEditDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                fullWidth
            >
                <DialogTitle id="dialog-title">{t("Edit instance")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <AnyField
                                id="Phone"
                                label={t("Phone")}
                                value={(rowData?.phone) ? rowData.phone : ''}
                                onChange={handleChange('phone')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="name"
                                label={t("Name")}
                                value={(rowData?.name) ? rowData.name : ''}
                                onChange={handleChange('name')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="minPauseMessage"
                                label={t("Min pause message")}
                                value={(rowData?.minPauseMessage) ? rowData.minPauseMessage : ''}
                                onChange={handleChange('minPauseMessage')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="minPauseBatch"
                                label={t("Min pause between batch")}
                                value={(rowData?.minPauseBatch) ? rowData.minPauseBatch : ''}
                                onChange={handleChange('minPauseBatch')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="minPauseLimiter"
                                label={t("Min pause reaching Limiter")}
                                value={(rowData?.minPauseLimiter) ? rowData.minPauseLimiter : ''}
                                onChange={handleChange('minPauseLimiter')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="countMessagesPerMinute"
                                label={t("Count messages per minute")}
                                value={(rowData?.countMessagesPerMinute) ? rowData.countMessagesPerMinute : ''}
                                onChange={handleChange('countMessagesPerMinute')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="apiTokenInstance"
                                label={t("Api token instance")}
                                value={(rowData?.apiTokenInstance) ? rowData.apiTokenInstance : ''}
                                onChange={handleChange('apiTokenInstance')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="countInBatch"
                                label={t("Count in batch")}
                                value={(rowData?.countInBatch) ? rowData.countInBatch : ''}
                                onChange={handleChange('countInBatch')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="maxPauseMessage"
                                label={t("Max pause message")}
                                value={(rowData?.maxPauseMessage) ? rowData.maxPauseMessage : ''}
                                onChange={handleChange('maxPauseMessage')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="maxPauseBatch"
                                label={t("Max pause between batch")}
                                value={(rowData?.maxPauseBatch) ? rowData.maxPauseBatch : ''}
                                onChange={handleChange('maxPauseBatch')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="maxPauseLimiter"
                                label={t("Max pause reaching Limiter")}
                                value={(rowData?.maxPauseLimiter) ? rowData.maxPauseLimiter : ''}
                                onChange={handleChange('maxPauseLimiter')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="instanceId"
                                label={t("Instance Id")}
                                value={(rowData?.instanceId) ? rowData.instanceId : ''}
                                onChange={handleChange('instanceId')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="queue"
                                label={t("Queue")}
                                value={(rowData?.queue) ? rowData.queue : ''}
                                onChange={handleChange('queue')}
                                visible={true}
                                style={{ width: "80%" }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={editInstance} color="primary">OK</Button>
                    <Button onClick={handleCloseEditDialog} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenCreate}
                onClose={() => setIsOpenCreate(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                fullWidth
            >
                <DialogTitle id="dialog-title">{t("Create instance")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <AnyField
                                id="Phone"
                                label={t("Phone")}
                                value={(newInstance?.phone) ? newInstance.phone : ''}
                                onChange={handleChangeCreate('phone')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="name"
                                label={t("Name")}
                                value={(newInstance?.name) ? newInstance.name : ''}
                                onChange={handleChangeCreate('name')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="minPauseMessage"
                                label={t("Min pause message")}
                                value={(newInstance?.minPauseMessage) ? newInstance.minPauseMessage : ''}
                                onChange={handleChangeCreate('minPauseMessage')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="minPauseBatch"
                                label={t("Min pause between batch")}
                                value={(newInstance?.minPauseBatch) ? newInstance.minPauseBatch : ''}
                                onChange={handleChangeCreate('minPauseBatch')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="minPauseLimiter"
                                label={t("Min pause reaching Limiter")}
                                value={(newInstance?.minPauseLimiter) ? newInstance.minPauseLimiter : ''}
                                onChange={handleChangeCreate('minPauseLimiter')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="apiTokenInstance"
                                label={t("Api token instance")}
                                value={(newInstance?.apiTokenInstance) ? newInstance.apiTokenInstance : ''}
                                onChange={handleChangeCreate('apiTokenInstance')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="instanceId"
                                label={t("Instance Id")}
                                value={(newInstance?.instanceId) ? newInstance.instanceId : ''}
                                onChange={handleChangeCreate('instanceId')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="countInBatch"
                                label={t("Count in batch")}
                                value={(newInstance?.countInBatch) ? newInstance.countInBatch : ''}
                                onChange={handleChangeCreate('countInBatch')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="maxPauseMessage"
                                label={t("Max pause message")}
                                value={(newInstance?.maxPauseMessage) ? newInstance.maxPauseMessage : ''}
                                onChange={handleChangeCreate('maxPauseMessage')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="maxPauseBatch"
                                label={t("Max pause between batch")}
                                value={(newInstance?.maxPauseBatch) ? newInstance.maxPauseBatch : ''}
                                onChange={handleChangeCreate('maxPauseBatch')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="maxPauseLimiter"
                                label={t("Max pause reaching Limiter")}
                                value={(newInstance?.maxPauseLimiter) ? newInstance.maxPauseLimiter : ''}
                                onChange={handleChangeCreate('maxPauseLimiter')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="queue"
                                label={t("Queue")}
                                value={(newInstance?.queue) ? newInstance.queue : ''}
                                onChange={handleChangeCreate('queue')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                            <AnyField
                                id="countMessagesPerMinute"
                                label={t("Count messages per minute")}
                                value={(newInstance?.countMessagesPerMinute) ? newInstance.countMessagesPerMinute : ''}
                                onChange={handleChangeCreate('countMessagesPerMinute')}
                                visible={true}
                                required={true}
                                style={{ width: "80%" }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={createInstance} disabled={disableBtn} color="primary">Создать</Button>
                    <Button onClick={isOpenCreateDialog} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={successDialog} style={{ bottom: 30 }}>
                <DialogTitle id="dialog-title">{t("Success")}</DialogTitle>
            </Dialog>

            <Dialog open={errorDialog} style={{ bottom: 30 }}>
                <DialogTitle id="dialog-title">{t("Error occurred")}</DialogTitle>
            </Dialog>
        </>
    )
}