import baseRequest from "./baseReuqest";
import * as queryString from "querystring";

export const navLinks = [
    { path: "/reports", text: "Report of whatsapp messages and visits"}
];

export function getWhatsAppMessagesVSVisits(data) {
    let url = `/api/report/whatsapp-messages-vs-visits`;
    const q = queryString.stringify({...data});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}



// export function getWhatsappMessages(page, per_page, order, order_by) {
//     let url = '/api/whatsapp';
//     const q = queryString.stringify({page, per_page, order, order_by});
//     if (q) {
//         url += `?${q}`;
//     }
//     return baseRequest(url);
// }
//
// export function loadMessage(id) {
//     let url = `/api/whatsapp/${id}`;
//     return baseRequest(url);
// }
//
// export function sendWhatsappMessage(data) {
//     return baseRequest(
//         `/api/whatsapp`,
//         {
//             method: 'POST',
//             body: {...data},
//         }
//     );
// }
//
// export function getWhatsappChannelsForTable(page, per_page, order, order_by) {
//     let url = '/api/whatsapp/channel';
//     return baseRequest(url);
// }
//
// export function updateWhatsAppChannels(data) {
//     return baseRequest(
//         `/api/whatsapp/channel/update`,
//         {
//             method: 'POST',
//             body: {...data},
//         }
//     );
// }
//
// export function getSynonyms(page, per_page, order, order_by) {
//     let url = '/api/whatsapp/channel';
//     return baseRequest(url);
// }
//
// export function phonesXLSXRowsToDTOArray(data) {
//     let keys = {
//         phone: 'Телефон',
//     };
//
//     let DTOArray = [];
//     let error = false;
//
//     data.forEach((row) => {
//         let dto = {};
//         for (let prop in keys) {
//             let XLSXKey = keys[prop];
//             if (typeof row[XLSXKey] === 'undefined') {
//                 error = 'Колонки "' + XLSXKey + '" не существует';
//                 return false;
//             }
//             dto[prop] = row[XLSXKey];
//         }
//         DTOArray.push(dto);
//     });
//
//     if (error) {
//         return error;
//     }
//
//     return DTOArray;
// }
