import {createActions} from 'redux-actions';

const {info} = createActions({
    INFO: {
        SHOW: undefined,
        SHOW_ERROR: undefined,
        HIDE: undefined,
    }
});

export default info;