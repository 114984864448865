import baseRequest from "./baseReuqest";

export function loadTemplates() {
    let url = '/api/ems/templates';
    return baseRequest(url);
}

export function loadTemplateById(id) {
    let url = `/api/ems/template/${id}`;
    return baseRequest(url);
}