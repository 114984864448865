import {createTheme} from "@material-ui/core";
import {amber, blue, purple, indigo} from "@material-ui/core/colors";

export function getLabelTransform(offsetY) {
    return `translate(0, ${offsetY}px) scale(1)`;
}

function getPrimaryColors() {
    let light, main, dark;
    switch (process.env.REACT_APP_VARIANT) {
        case 'TEST':
            light = blue[300];
            main = blue[500];
            dark = blue[700];
            break;
        case 'PROD':
            light = purple[300];
            main = purple[500];
            dark = purple[700];
            break;
        case 'NKS':
            light = indigo[300];
            main = indigo[500];
            dark = indigo[700];
            break;
        default:
            light = '#622c8b';
            main = '#3c1a53';
            dark = '#1e0f28';
            break;
    }

    // if (window.location.hostname === 'maitai.nk-soft.com') {
    //     light = '#ff0000';
    //     main = '#ff0000';
    //     dark = '#ff0000';
    // }

    return { light, main, dark };
}

export const mainTheme = createTheme({
    palette: {
        primary: getPrimaryColors(),
        secondary: {
            light: amber[300],
            main: amber[500],
            dark: amber[700],
            contrastText: purple[700]
        },
    },
    typography: {
        fontSize: 14,
        body2: {
            lineHeight: "normal",
        },
    },
    overrides: {
        MuiPrivateNotchedOutline: {
            root: {
                borderRadius: 0,
                top: 0,
            },
            legend: {
                display: 'none',
            },
        },
        MuiNotchedOutline: {
            root: {
                borderRadius: 0,
            },
            legend: {
                display: 'none',
            },
        },
        PrivateNotchedOutline: {
            root: {
                top: 0,
                borderRadius: 0,
            },
            legendNotched: {
                display: 'none',
            },
            legend: {
                display: 'none',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 5px',
            },
            adornedEnd: {
                paddingRight: 8,
            },
            multiline: {
                padding: '8px 5px',
            },
        },
        MuiInputLabel: {
            outlined: {
                '&$shrink': {
                    transform: getLabelTransform(-18),
                }
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                '&$focused': {
                    backgroundColor: 'transparent',
                },
            }
        },
        MuiTableRow: {
            root: {
                height: '32px',
            },
            head: {
                height: '32px',
            },
        },
        MuiTableCell: {
            root: {
                padding: '4px 8px',
            },
        },
        MuiTablePagination: {
            toolbar: {
                height: '32px',
                minHeight: '32px',
            },
            actions: {
                '& .MuiIconButton-root': {
                    padding: '0 12px',
                },
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
            },
        },
        MuiPickersDay: {
            current: {
                border: '1px solid #999',
            },
        },
    },
    props: {
        MuiTextField: {
            variant: "outlined",
            InputLabelProps: {
                shrink: true
            }
        }
    }
});

export const dateFieldTheme = createTheme({
    ...mainTheme,
    overrides: {
        ...mainTheme.overrides,
        MuiIconButton: {
            root: {
                padding: '2px'
            }
        },
    },
})