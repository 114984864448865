import { combineReducers } from 'redux';
import auth from './pages/login/auth-reducer';
import info from './components/info/info-reducer';
import message from './components/dialogs/messageDialog-reducer';
import confirm from './components/dialogs/confirmDialog-reducer';
import backgroundProcess from './components/background-progress/background-process-reducer';

export default combineReducers({
    auth,
    info,
    message,
    confirm,
    backgroundProcess,
});
