import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {navLinks, getWhatsappMessages} from "../../services/whatsapp"
import _ from "lodash";

export default
@withRouter
@withTranslation()
class SynonymsPage extends React.Component {

    state = {
        search: '',
        refreshTable: false
    };

    columnData = [
        { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
        { id: 'dateCreated', label: 'DAteCreated', dateFormat: "DD.MM.YYYY" },
        { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
        {
            id: 'additionalParameters',
            label: 'Parameters',
            numeric: false,
            processValue: (data) => {
                let returnData = [];

                if (_.size(data) > 0) {
                    _.forEach(data, (i, el) => {
                        returnData.push( <p>{`${i} : ${el}`}</p> );
                    })
                }

                return returnData;
            }
        },
        { id: 'messageType', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'messageConnectInitiator', numeric: false, disablePadding: false, label: 'Initiator' },
    ];

    getTableData = (...params) => {
        return getWhatsappMessages(...params);
    };

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <p>{t('List of messages')}</p>
                </LeftBar>
                <Content title={t("Messages")}>
                    <p>In progress...</p>
                    {/*<DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/whatsapp/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />*/}
                </Content>
            </React.Fragment>
        );
    }
}
