import React from 'react';
import {Route, Switch} from "react-router";
import GeneralSettings from "./GeneralSettings";

class SettingsPage extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/settings" component={GeneralSettings}/>
            </Switch>
        );
    }
}

export default SettingsPage;