import baseRequest from "./baseReuqest";

export const navLinks = [];

export function getGeneralSettings() {
    return baseRequest('/api/settings/general');
}

export function getGeneralProperties() {
    return baseRequest('/api/settings/general-properties');
}

export function saveGeneralSettings(settings) {
    return baseRequest('/api/settings/general',{
        method: 'PUT',
        body: settings,
    });
}

export function getDiscountCards(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/sale/discount-card', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getDiscountCard(id) {
    return baseRequest(`/api/sale/discount-card/id/${id}`);
}

export function getDiscountCardTypes() {
    return baseRequest('/api/sale/discount-card/type');
}

export function saveDiscountCard(data) {
    return baseRequest('/api/sale/discount-card',{
        method: 'POST',
        body: data,
    });
}

export const emptyDiscountCard = {
    type: null,
    num: null,
    pin: null,
    value: null,
}