import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getProcesses, navLinks} from "../../services/processes";
import _ from "lodash";

export default
@withRouter
@withTranslation()
class ListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false
    };

    columnData = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Process name' },
        { id: 'enable', numeric: false, disablePadding: false, label: 'Enable' },
        { id: 'scheduleMainStart', label: 'Schedule main start', dateFormat: "DD.MM.YYYY" },
        { id: 'scheduleMainStop', label: 'Schedule main stop', dateFormat: "DD.MM.YYYY" },
        { id: 'scheduleDailyStart', label: 'Schedule daily start', dateFormat: "HH:mm" },
        { id: 'scheduleDailyStop', label: 'Schedule daily stop', dateFormat: "HH:mm" },
        { id: 'disposable', numeric: false, disablePadding: false, label: 'Disposable' },
        { id: 'reusable', numeric: false, disablePadding: false, label: 'Reusable' },
        {
            id: 'erpCreator',
            numeric: false,
            disablePadding: false,
            label: 'ERP creator',
            processValue: (data) => {
                let returnData = [];

                if (_.size(data) > 0) {
                    _.forEach(data, (el, i) => {
                        returnData.push( <p>{`${i} : ${el}`}</p> );
                    })
                }

                return returnData;
            }
        },
        {
            id: 'channel', numeric: false, disablePadding: false, label: 'Channel',
            processValue: (data) => {
                let returnData = [];
                let requiredFields = ['phone', 'queue', 'transport', 'initiator'];
                if (_.size(data) > 0) {
                    _.forEach(data, (el, i) => {
                        if (requiredFields.includes(i) ) {
                            returnData.push(<p>{`${i} : ${el}`}</p>);
                        }
                    })
                }

                return returnData;
            }
        },

    ];

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/process/edit/0`);
    };

    getTableData = (...params) => {
        return getProcesses(...params);
    };

    render() {
        const { t } = this.props;
        const { refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd}>{t("Add")}</ActionButton>
                </LeftBar>
                <Content title={t("Processes")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/process/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
