const LSKEY_TOKEN = 'token';
const LSKEY_CURRENT_PARLOUR = 'ttCurrentParlour';
const LSKEY_REDIRECT_URI = 'ttRedirectUri';
const LSKEY_HIDDEN_STATUSES = 'ttHiddenStatuses';
const LSKEY_CALENDAR_CURRENT_DATE = 'ttCalendarCurrentDate';
const LSKEY_CALENDAR_BUSINESS_UNIT_FILTER = 'ttCalendarBusinessUnitFilter';

export const loadToken = () => loadObjectFromLs(LSKEY_TOKEN);
export const saveToken = token => saveObjectToLs(LSKEY_TOKEN, token);

export const loadCurrentParlour = () => loadObjectFromLs(LSKEY_CURRENT_PARLOUR);
export const saveCurrentParlour = parlour => saveObjectToLs(LSKEY_CURRENT_PARLOUR, parlour);
export const loadCalendarCurrentDate = () => loadFromLs(LSKEY_CALENDAR_CURRENT_DATE);
export const saveCalendarCurrentDate = (currentDate) => saveToLs(LSKEY_CALENDAR_CURRENT_DATE, currentDate);
export const saveCalendarBusinessUnitFilter = filter => saveObjectToLs(LSKEY_CALENDAR_BUSINESS_UNIT_FILTER, filter);
export const loadCalendarBusinessUnitFilter = () => loadObjectFromLs(LSKEY_CALENDAR_BUSINESS_UNIT_FILTER);

export const loadRedirectUri = () => loadFromLs(LSKEY_REDIRECT_URI);
export const saveRedirectUri = uri => saveToLs(LSKEY_REDIRECT_URI, uri);

export const loadHiddenStatuses = () => loadObjectFromLs(LSKEY_HIDDEN_STATUSES);
export const saveHiddenStatuses = hiddenStatuses => saveObjectToLs(LSKEY_HIDDEN_STATUSES, hiddenStatuses);

//Mfr - Money flow report
const LSKEY_MFR_TRANSACTION_CASH_FILTER = 'ttMfrTransactionsCashFilter';

export const loadMfrTransactionsCashFilter = () => loadObjectFromLs(LSKEY_MFR_TRANSACTION_CASH_FILTER);
export const saveMfrTransactionsCashFilter = filter => saveObjectToLs(LSKEY_MFR_TRANSACTION_CASH_FILTER, filter);

//Vr - Visits report
const LSKEY_VR_REQUESTED_VISITS_FILTER = 'ttVrRequestedVisitsFilter';
const LSKEY_VR_FULL_VISITS_FILTER = 'ttVrFullVisitsFilterV3';

export const loadVrRequestedVisitsFilter = () => loadObjectFromLs(LSKEY_VR_REQUESTED_VISITS_FILTER);
export const saveVrRequestedVisitsFilter = filter => saveObjectToLs(LSKEY_VR_REQUESTED_VISITS_FILTER, filter);
export const loadVrFullVisitsFilter = () => loadObjectFromLs(LSKEY_VR_FULL_VISITS_FILTER);
export const saveVrFullVisitsFilter = filter => saveObjectToLs(LSKEY_VR_FULL_VISITS_FILTER, filter);

//Tools - Sales
const LSKEY_TL_SALES_FILTER = 'ttTlSalesFilter';
const LSKEY_TL_DATA_TABLE_PARAMS = 'ttTlDataTableParams';

export const loadTlSalesFilter = () => loadObjectFromLs(LSKEY_TL_SALES_FILTER);
export const saveTlSalesFilter = filter => saveObjectToLs(LSKEY_TL_SALES_FILTER, filter);
export const loadTlDataTableParams = () => loadObjectFromLs(LSKEY_TL_DATA_TABLE_PARAMS);
export const saveTlDataTableParams = params => saveObjectToLs(LSKEY_TL_DATA_TABLE_PARAMS, params);

function loadObjectFromLs(key) {
    if (window.localStorage && window.localStorage.getItem(key)) {
        try {
            return JSON.parse(window.localStorage.getItem(key));
        } catch (e) {

        }
    }

    return undefined;
}

function saveObjectToLs(key, value) {
    try {
        if (window.localStorage) {
            if (value) {
                window.localStorage.setItem(key, JSON.stringify(value));
            } else {
                window.localStorage.removeItem(key);
            }
        }
    } catch (e) {
        //
    }
}
function loadFromLs(key) {
    if (window.localStorage) {
        try {
            return window.localStorage.getItem(key);
        } catch (e) {

        }
    }

    return undefined;
}

function saveToLs(key, value) {
    try {
        if (window.localStorage) {
            if (value) {
                window.localStorage.setItem(key, value);
            } else {
                window.localStorage.removeItem(key);
            }
        }
    } catch (e) {
        //
    }
}

