import { handleActions } from 'redux-actions';
import backgroundProcessActions from './background-process-actions';
import getStore from "../../store";

const showDelay = 200;
let timer = null;

export default handleActions({
    [backgroundProcessActions.startProcess]: (state, action) => {
        if (!timer && !state.visible) {
            timer = setTimeout(() => { getStore().dispatch(backgroundProcessActions.show())}, showDelay );
        }
        return { ...state, processCount: state.processCount + 1 };
    },
    [backgroundProcessActions.finishProcess]: (state, action) => {
        let processCount = state.processCount;
        let visible = state.visible;
        processCount--;
        if (processCount === 0) {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            visible = false;
        }
        return { ...state, processCount, visible};
    },
    [backgroundProcessActions.show]: (state, action) => {
        if (state.processCount > 0) {
            clearTimeout(timer);
            timer = null;

            return {...state, visible: true};
        }

        return state;
    },
    [backgroundProcessActions.hide]: (state, action) => {
        return {...state, visible: false}
    },
}, {
    visible: false,
    processCount: 0,
})
