import baseRequest from "./baseReuqest";
import moment from "moment";
import XLSX from "xlsx";

export const defaultCurrency = 'RUB';

export const emptyMoney = { val: null, cur: null };

export const zeroMoney = { val: 0, cur: null };

export const emptyDelivery = {
    id: null,
    type: null,
    paymentType: null,
    provider: null,
    providerId: null,
    waybill: null,
    receiptScan: null,
    receiptScanId: null,
    charges: {...emptyMoney},
    comment: null,
    courier: null,
    courierId: null,
    inversePayer: false
};

export function getMeasureUnits() {
    return baseRequest('/api/common/type/units');
}

export function getCurrencies() {
    return baseRequest('/api/common/type/currencies');
}

export function getSalableEntityTypes() {
    return baseRequest('/api/common/salable-entity-types');
}

export function getDeliveryTypes() {
    return baseRequest('/api/common/type/delivery');
}

export function getTransportCompanies() {
    return baseRequest('/api/organization/transport-companies');
}

export function getCouriers() {
    return baseRequest('/api/organization/couriers');
}

export function getTimeZones() {
    return baseRequest('/api/common/time-zones');
}

export function getMessengers() {
    return baseRequest(`/api/common/messengers`);
}

export function getEvents() {
    return baseRequest(`/api/common/events`);
}

export function getRegionEvents() {
    return baseRequest(`/api/common/region-events`);
}

export function getTelegramChats() {
    return baseRequest(`/api/telegram/chats`);
}

export function currentSystemSpendingLimit(businessUnitId, spendingTypeSystem) {
    let url = '/api/money-flow/limit/current-system-spending';
    return baseRequest(url, {
        method: 'POST',
        body: JSON.stringify({businessUnitId, spendingTypeSystem}),
    });
}

export function formatMoney(money) {
    return (money === undefined || money === null || money.val === null) ? '' : parseFloat(money.val).toLocaleString('ru',{ style: "currency", currency: money.cur ? money.cur : defaultCurrency});
}

export function getFalseResult(message = '') {
    return Promise.resolve({success: false, message});
}

export function sendTestTelegram(data) {
    return baseRequest('/api/test/telegram', {
        method: "POST",
        body: data,
    });
}

export const dataTableGroupValueStrategy = {
    count: (currentCount) => {
        currentCount = currentCount || 0;
        return currentCount + 1;
    },
    sum: (key) => (currentSum, row) => {
        currentSum = currentSum || 0;
        return currentSum + parseFloat(row[key]);
    },
    concat: (key, all, delimiter) => (currentStr, row) => {
        if (row[key] === null || row[key] === undefined) {
            return currentStr;
        }

        if (!currentStr) {
            return String(row[key]);
        }

        if (!all && currentStr.includes(row[key])) {
            return currentStr;
        }

        delimiter = delimiter || ', ';
        return currentStr + delimiter + String(row[key]);
    },
    moneySum: (key) => (currentSum, row) => {
        if (row && row[key]) {
            currentSum = currentSum ? currentSum.val : 0;
            let newMoney = {...row[key]};
            newMoney.val = (newMoney ? newMoney.val : 0) + currentSum;
            return newMoney;
        }

        return currentSum;
    },
    moneySumWithCurrencyToArray: (key) => (currentSum, row) => {
        currentSum = currentSum || [];

        if (row && row[key]) {
            let found = false;
            for (let i = 0; i < currentSum.length; i++) {
                if (currentSum[i].cur === row[key].cur) {
                    currentSum[i].val += row[key].val;
                    found = true;
                    break;
                }
            }
            if (!found) {
                currentSum.push({...row[key]});
            }
        }

        return currentSum;
    },
    arrayMoneySum: (key) => (currentSum, row) => {
        currentSum = currentSum || [];
        if (!Array.isArray(row[key])) {
            return currentSum;
        }
        row[key].forEach(money => {
            let found = false;
            for (let i = 0; i < currentSum.length; i++) {
                if (currentSum[i].cur === money.cur) {
                    currentSum[i].val += money.val;
                    found = true;
                    break;
                }
            }
            if (!found) {
                currentSum.push({...money});
            }
        });

        return currentSum;
    },
    fromTo: (key) => (currentArr, row) => {
        currentArr = currentArr || [];
        let time = moment(row[key]).unix();
        if ( !currentArr[0] || currentArr[0] > time) {
            currentArr[0] = time;
        }
        if ( !currentArr[1] || currentArr[1] < time) {
            currentArr[1] = time;
        }
        return currentArr;
    },
    same: (key) => (currentVal, row) => {
        return row ? row[key] : null;
    },
    appendArray: (key) => (currentArr, row) => {
        currentArr = currentArr || [];
        if (row && row[key]) {
            currentArr = currentArr.concat(row[key]);
        }
        return currentArr;
    }
};

export function fromToDates(format) {
    return (dates) => dates && dates.length >= 2 ?
        moment.unix(dates[0]).format(format) + ' - ' + moment.unix(dates[1]).format(format) :
        null;
}

export function formatArrayMoney(arrayMoney) {
    return arrayMoney && Array.isArray(arrayMoney) ? arrayMoney.map(money => formatMoney(money)).join(', ') : null;
}

export function formatArrayMoneyMultiLine(arrayMoney) {
    return arrayMoney && Array.isArray(arrayMoney) ? arrayMoney.map(money => formatMoney(money)).join("\n") : null;
}


export function formatArrayMoneyForExcel(arrayMoney) {
    if (arrayMoney && Array.isArray(arrayMoney)) {
        if (arrayMoney.length === 1) {
            return arrayMoney[0].val;
        }

        return arrayMoney.map(money => formatMoney(money)).join(', ');
    }
    return null;
}

export function formatArrayMoneyForExcelMultiline(arrayMoney) {
    if (arrayMoney && Array.isArray(arrayMoney)) {
        if (arrayMoney.length === 1) {
            return arrayMoney[0].val;
        }

        return arrayMoney.map(money => formatMoney(money)).join("\n");
    }
    return null;
}

export function formatArrayString(arrayString) {
    if (Array.isArray(arrayString)) {
        return arrayString.join(', ');
    }

    return arrayString;
}

export function getSecuredEvents() {
    return baseRequest(`/api/common/secured-events`);
}

export function parseXLS(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let json;
        reader.onload = (e) => {
            let data = e.target.result;
            let wb = XLSX.read(data, {type:"binary"});
            json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {defval: '', raw: false});
            resolve(json);
        };
        reader.onerror = (e) => {
            alert('Error read file: ' + e.target.error.code);
            reject(e.target.error);
        };
        reader.readAsBinaryString(file);
    });
}

export const gratuityQRTypes = {
    parlour: 'parlour',
    employee: 'employee'
}

export function getGratuityQRImage(id, type) {
    return `/public-api/gratuity-link?id=${id}&type=${type}`
}

export function getFilterPresetsByType(type) {
    return baseRequest(`/api/common/filter-presets/${type}`);
}

export function saveFilterPreset(data) {
    return baseRequest(`/api/common/filter-presets`, {
        method: 'POST',
        body: data,
    });
}

export function removeFilterPreset(id) {
    return baseRequest(`/api/common/filter-presets/${id}`, {
        method: 'DELETE'
    });
}

export const topBarSettings = {
    startHeight: 48,
    minHeight: 24,
    startFontSize: 14,
    minFontSize: 11,
    logoStartHeight: 40,
    logoMinHeight: 20,
    logoPaddingStart: 16,
    logoPaddingMin: 4,
}

export function getDynamicDateFieldTypes() {
    return baseRequest(`/api/common/dynamic-date-types`);
}

export function getVisitElaborationDegreeTypes() {
    return baseRequest(`/api/common/visit-elaboration-degree-types`);
}
