import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from "moment";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getSendingStatistics, navLinks} from "../../services/whatsapp"




export default
@withRouter
@withTranslation()
class SendingStatisticsPage extends React.Component {

    state = {
        search: '',
        refreshTable: false
    };

    columnData = [
        { id: 'minutesStat', numeric: false, disablePadding: false, label: 'Phone' },
    ];
    statData = {
        minutesData:[],
        hoursData:[],
        daysData:[],
        monthsData:[],
    };

    componentDidMount() {
        getSendingStatistics()
            .then((response) => {
                if (response.success) {
                    // console.log('response.data', response.data)
                    response.data.minutesData.map(
                        (info)=>{
                            let time = moment(info.date_trunc, 'YYYY-MM-DD HH:mm:ss').add(8, 'hours');
                            this.statData.minutesData.push([time, info.count]);
                            return true;
                        });
                    response.data.hoursData.map(
                        (info)=>{
                            let time = moment(info.date_trunc, 'YYYY-MM-DD HH:mm:ss').add(8, 'hours');
                            this.statData.hoursData.push([time, info.count]);
                            return true;
                        });
                    response.data.daysData.map(
                        (info)=>{
                            let time = moment(info.date_trunc, 'YYYY-MM-DD HH:mm:ss').add(8, 'hours');
                            this.statData.daysData.push([time, info.count]);
                            return true;
                        });
                    response.data.monthsData.map(
                        (info)=>{
                            let time = moment(info.date_trunc, 'YYYY-MM-DD HH:mm:ss').add(8, 'hours');
                            this.statData.monthsData.push([time, info.count]);
                            return true;
                        });
                    return true;
                }
            });
    }

    render() {
        // console.log('this.statData.minutesData',this.statData.minutesData)
        const { t } = this.props;
        const optionsMinutes = {
            series: [{
                type: 'area',
                // data: [[1678867980, 2],[1678868040, 5],[1678868100, 5]],
                data: this.statData.minutesData.map(([timeMin, count]) => [moment(timeMin).valueOf(), count]),
            }],
            title: {
                text: 'Статистика отправки сообщений в минуту (срез - сегодня)',
                align: 'left'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Количество сообщений'
                }
            },
            legend: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 1
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
        }
        const optionsHours = {
            series: [{
                type: 'area',
                // data: [[1678867980, 2],[1678868040, 5],[1678868100, 5]], дата для демо
                data: this.statData.hoursData.map(([time, count]) => [moment(time).valueOf(), count]),
            }],
            title: {
                text: 'Количество сообщений в час (срез - сегодня и вчера)',
                align: 'left'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Количество сообщений'
                }
            },
            legend: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 1
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
        }
        const optionsDays = {
            series: [{
                type: 'area',
                // data: [[1678867980, 2],[1678868040, 5],[1678868100, 5]], дата для демо
                data: this.statData.daysData.map(([time, count]) => [moment(time).valueOf(), count]),
            }],
            title: {
                text: 'Количество сообщений по дням (срез последние 7 дней)',
                align: 'left'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Количество сообщений'
                }
            },
            legend: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 1
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
        }
        const optionsMonths = {
            series: [{
                type: 'area',
                // data: [[1678867980, 2],[1678868040, 5],[1678868100, 5]], дата для демо
                data: this.statData.monthsData.map(([time, count]) => [moment(time).valueOf(), count]),
            }],
            title: {
                text: 'Количество сообщений по месяцам (срез последние 12 месяцев)',
                align: 'left'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Количество сообщений'
                }
            },
            legend: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 1
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
        }
        return (

            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <p>{t('List of statistics')}</p>
                </LeftBar>
                <Content title={t("Statistics")}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsMinutes}
                    />
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsHours}
                    />
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsDays}
                    />
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsMonths}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
