import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";
import {emptyFieldColor, fillFieldColor} from "../../services/constants";
import * as PropTypes from "prop-types";
import classNames from 'classnames';
import _ from 'lodash';

const styles = theme => ({
    root: {
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
});

export const propTypes = {
    /**
     * This property helps users to fill forms faster, especially on mobile devices.
     * The name can be confusing, as it's more like an autofill.
     * You can learn more about it here:
     * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
     */
    autoComplete: PropTypes.string,

    /**
     * If `true`, the input will be focused during the first mount.
     */
    autoFocus: PropTypes.bool,

    /**
     * @ignore
     */
    children: PropTypes.node,

    /**
     * @ignore
     */
    className: PropTypes.string,

    /**
     * The default value of the `Input` element.
     */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * If `true`, the input will be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If `true`, the label will be displayed in an error state.
     */
    error: PropTypes.bool,

    /**
     * Properties applied to the [`FormHelperText`](/api/form-helper-text/) element.
     */
    FormHelperTextProps: PropTypes.object,

    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth: PropTypes.bool,

    /**
     * The helper text content.
     */
    helperText: PropTypes.node,

    /**
     * The id of the `input` element.
     * Use that property to make `label` and `helperText` accessible for screen readers.
     */
    id: PropTypes.string,

    /**
     * Properties applied to the [`InputLabel`](/api/input-label/) element.
     */
    InputLabelProps: PropTypes.object,

    /**
     * Properties applied to the `Input` element.
     */
    InputProps: PropTypes.object,

    /**
     * Attributes applied to the native `input` element.
     */
    inputProps: PropTypes.object,

    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    /**
     * The label content.
     */
    label: PropTypes.node,

    /**
     * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
     */
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),

    /**
     * If `true`, a textarea element will be rendered instead of an input.
     */
    multiline: PropTypes.bool,

    /**
     * Name attribute of the `input` element.
     */
    name: PropTypes.string,

    /**
     * @ignore
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when the value is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     */
    onChange: PropTypes.func,

    /**
     * @ignore
     */
    onFocus: PropTypes.func,

    /**
     * The short hint displayed in the input before the user enters a value.
     */
    placeholder: PropTypes.string,

    /**
     * If `true`, the label is displayed as required and the input will be required.
     */
    required: PropTypes.bool,

    /**
     * Number of rows to display when multiline option is set to true.
     */
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Maximum number of rows to display when multiline option is set to true.
     */
    rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Render a `Select` element while passing the `Input` element to `Select` as `input` parameter.
     * If this option is set you must pass the options of the select as children.
     */
    select: PropTypes.bool,

    /**
     * Properties applied to the [`Select`](/api/select/) element.
     */
    SelectProps: PropTypes.object,

    /**
     * Type attribute of the `Input` element. It should be a valid HTML5 input type.
     */
    type: PropTypes.string,

    /**
     * The value of the `Input` element, required for a controlled component.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))]),

    readOnly: PropTypes.bool,

    visible: PropTypes.bool,

    nullValue: PropTypes.any,
};


@withStyles(styles)
class AnyField extends React.PureComponent
{
    render() {
        const { value, label, InputProps, InputLabelProps, className, classes, readOnly, visible, nullValue, select, SelectProps, children, ...otherProps } = this.props;

        if (!visible) {
            return null;
        }

        let selectNotFound = false;

        if (select && !(SelectProps && SelectProps.multiple) && value) {
            selectNotFound = !React.Children.toArray(children)
                .some(child => child.props && _.eq(value, child.props.value));
        }

        return <TextField
            value={value === null || value === undefined || selectNotFound ? '' : value}
            InputProps={{
                ...InputProps,
                style: {
                    ...(InputProps ? InputProps.style : {}),
                    background: (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0) || (nullValue && value === nullValue)) ? emptyFieldColor : fillFieldColor,
                },
                readOnly: readOnly,
                autoComplete: 'disabled',
            }}
            select={select}
            children={children}
            SelectProps={SelectProps}
            {...otherProps}
            variant="outlined"
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true
            }}
            label={label}
            className={classNames(classes.root,{[classes.withLabel]: label}, className)}
        />;
    }
}

// Тут работаеть только так, чтобы propTypes можно было использовать
AnyField.propTypes = propTypes;

AnyField.defaultProps = {
    visible: true,
    select: false,
};

export default AnyField;