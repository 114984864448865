import React from 'react';
import NumberFormat from 'react-number-format';
import AnyField from "./AnyField";

function NumberFormatInteger(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            thousandSeparator=" "
            allowNegative
            decimalScale={0}
        />
    );
}

export default
class IntegerField extends React.PureComponent {

    render () {
        const { InputProps, ...otherProps } = this.props;

        return <AnyField
            {...otherProps}
            InputProps={{
                ...InputProps,
                inputComponent: NumberFormatInteger,
            }}
        />;
    }
}