import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getTasks, navLinks} from "../../services/tasks";
// import _ from "lodash";

export default
@withRouter
@withTranslation()
class ListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false
    };

    columnData = [
        {
            id: 'task',
            label: 'Task actions',
            numeric: false,
            processValue: () => {
                return <p>{this.props.t('Show task actions')}</p>;
            }
        },
        { id: 'name', numeric: false, label: 'Name' },
        {
            id: 'process',
            label: 'Process',
            numeric: false,
            processValue: (process) => {
                return <a onClick={ () => { this.goToProcess(process.id) } } href={`${process.id}`}>{`${process.name}`}</a>;
            }
        },
        { id: 'scheduleDailyStart', label: 'Schedule daily start', dateFormat: "HH:mm" },
        { id: 'scheduleDailyStop', label: 'Schedule daily stop', dateFormat: "HH:mm" },
        { id: 'actualStart', label: 'Actual start', dateFormat: "DD.MM.YYYY HH:mm" },
        { id: 'taskActionsCount', numeric: false, label: 'Tasks actions count' },
        { id: 'taskActionRunningCount', numeric: false, label: 'Running tasks actions count' },
        { id: 'taskActionSuccessCount', numeric: false, label: 'Success tasks actions count' },
        { id: 'taskActionFailCount', numeric: false, label: 'Fail tasks actions count' },
    ];

    goToProcess = processId => {
        this.props.history.push(`process/view/${processId}`);
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        return getTasks(...params);
    };

    render() {
        const { t } = this.props;
        const { refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <p>{t("Tasks")}</p>
                </LeftBar>
                <Content title={t("Tasks")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/tasks/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
