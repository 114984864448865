import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import NewPage from "./NewPage";
import ChannelsPage from "./ChannelsPage";
import SynonymsPage from "./SynonymsPage";
import SendingStatisticsPage from "./SendingStatisticsPage";

class WhatsappPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/whatsapp"               component={ListPage}/>
                <Route       path="/whatsapp/new"           component={NewPage}/>
                <Route       path="/whatsapp/channels"      component={ChannelsPage}/>
                <Route       path="/whatsapp/synonyms"      component={SynonymsPage}/>
                <Route       path="/whatsapp/sending-statistics"      component={SendingStatisticsPage}/>
            </Switch>
        );
    }
}

export default WhatsappPage;