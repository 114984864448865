import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid
} from '@material-ui/core';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {navLinks, sendWhatsappMessage} from "../../services/whatsapp";
import CircularProgress from '@material-ui/core/CircularProgress';
import AnyField from "../../components/fields/AnyField";
import {importSpending} from "../../services/moneyFlow";
import ImportDialog from "./ImportDialog";

export default
@withRouter
@withTranslation()
@connect(null,{
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    container: {
        width: 700,
    },
    item: {
        padding: 5,
    },
})
class NewPage extends React.Component {

    state = {
        loading: false,
        message: {
            text: null,
            phone: null
        },
        openImportModal: false
    };

    componentDidMount() {}


    back = () => {
        this.props.history.push('/whatsapp/');
    }

    handleMessageChange = prop => event => {
        const { message } = this.state;

        this.setState({
            message: {
                ...message,
                [prop]: event.target.value,
            }
        });
    }

    sendMessage = () => {
        const { message } = this.state;
        const { showInfo, history } = this.props;

        sendWhatsappMessage(message).then( (response) => {
            if (response.success) {
                history.push('/whatsapp/');
            } else {
                showInfo( (response?.error?.message) ? response.error.message: 'No error message');
            }
        } )
    }

    render() {
        const { classes, t } = this.props;
        const { message, notFound, loading, openImportModal } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton
                        onClick={this.back}>{t("Back")}
                    </ActionButton>
                    <ActionButton onClick={() => { this.setState({ openImportModal: true }) }}>{t("Send by xlsx file")}</ActionButton>
                </LeftBar>
                <Content title={t('Message')} notFoundContent={notFound}>
                    { loading && <CircularProgress /> }
                    <Grid container={true}>
                        <Grid item xs={3} className={classes.item}>
                            <AnyField
                                id="Phone"
                                label={t("Phone")}
                                value={message.phone}
                                onChange={this.handleMessageChange('phone')}
                                fullWidth
                                required={true}
                                error={!message.phone}
                            />
                        </Grid>
                        <Grid item xs={9} className={classes.item}>
                            <AnyField
                                id="Text"
                                label={t("Text")}
                                value={message.text}
                                onChange={this.handleMessageChange('text')}
                                fullWidth
                                required={true}
                                error={!message.text}
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <ActionButton onClick={this.sendMessage}>{t("Send")}</ActionButton>
                        </Grid>
                    </Grid>
                    {openImportModal && <ImportDialog open={openImportModal} onClose={() => this.setState({openImportModal: false})} title={t("Send by xlsx file")} importFunc={importSpending}/> }
                </Content>
            </React.Fragment>
        );
    }
}
