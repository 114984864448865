import React, { Component } from 'react';
import {Paper, Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import authActions from './auth-actions';
import AnyField from "../../components/fields/AnyField";
import LoadingButton from "../../components/button/LoadingButton";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {createChangeHandle} from "../../utils/helpers";

export default
@withStyles(theme => ({
    root: {
        textAlign: 'center',
        flexGrow: 1,
    },
    paper: theme.mixins.gutters({
        padding:  theme.spacing(1),
        marginTop:  theme.spacing(3),
        width: 300,
        display: 'inline-block',
    }),
    error: {
        color: theme.palette.error.main,
    },
    button: {
        margin:  theme.spacing(1),
    },
    linkRecovery: {
        paddingTop:  theme.spacing(1),
    }
}))
@connect(state => ({
    isLoginPending: state.auth.isLoginPending,
    loginError: state.auth.loginError,
}), {
    login: authActions.login
})
@withTranslation()
class LoginPage extends Component {

    state = {
        login: '',
        password: '',
        passwordRecovery: false,
    };

    componentDidMount() {
        // checkPasswordRecovery()
        //     .then(response => {
        //         if (response.success) {
        //             this.setState({
        //                 passwordRecovery: response.data
        //             })
        //         }
        //     })
    }

    handleChange = createChangeHandle(this)

    validateForm = () => {
        const {login, password} = this.state;

        return login.length && password.length;
    };

    handleSubmit = event => {
        const {login, password} = this.state;
        this.props.login(login, password);

        event.preventDefault();
        event.stopPropagation();
    };

    render() {
        const { classes, isLoginPending, loginError, t } = this.props;
        const { passwordRecovery, login, password } = this.state;

        return <div className={classes.root}>
            <Paper className={classes.paper} square>
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label={t("Login")}
                                autoFocus={true}
                                value={login}
                                name="login"
                                onChange={this.handleChange}
                                disabled={isLoginPending}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label={t("Password")}
                                type="password"
                                value={password}
                                name="password"
                                onChange={this.handleChange}
                                disabled={isLoginPending}
                                fullWidth
                            />
                        </Grid>
                        { loginError &&
                            <Grid item xs={12} className={classes.error}>{loginError}</Grid>
                        }
                        <Grid item xs={12}>
                            <LoadingButton className={classes.button} type="submit" disabled={!this.validateForm() || isLoginPending} loading={isLoginPending}>{t('Entry')}</LoadingButton>
                        </Grid>
                        {
                            passwordRecovery &&
                                <Grid item xs={12}>
                                    <Link className={classes.linkRecovery} to="/password-recovery">{t('Password recovery')}</Link>
                                </Grid>

                        }
                    </Grid>
                </form>
            </Paper>
        </div>;
    }
}
