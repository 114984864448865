import React from 'react';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import * as PropTypes from 'prop-types';
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

export default
@withStyles(theme => ({
    root: {
        marginLeft: - theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    checkbox: {
        padding: 5,
    },
    checkboxDense: {
        padding: '0 2px 0 5px',
    }
}))
class CheckboxField extends React.PureComponent
{
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.bool,
        onChange: PropTypes.func,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        margin: PropTypes.oneOf(['label', 'none']),
        checkboxStyle: PropTypes.object,
        indeterminate: PropTypes.bool,
        dense: PropTypes.bool,
        name: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        margin: 'label',
        indeterminate: false,
        dense: false,
    };

    handleChange = event => {
        const { onChange, name } = this.props;
        if (onChange) {
            onChange({...event, target: {...event.target, name, value: event.target.checked}})
        }
    };

    render() {
        const { classes, className, value, name, label, disabled, margin, checkboxStyle, indeterminate, dense } = this.props;

        return <FormControlLabel
            className={classNames(classes.root,{[classes.withLabel]: margin === 'label'}, className)}
            control={
                <Checkbox
                    checked={value || false}
                    onChange={this.handleChange}
                    value={name}
                    name={name}
                    className={dense ? classes.checkboxDense : classes.checkbox}
                    style={checkboxStyle}
                    indeterminate={indeterminate}
                    disabled={disabled}
                />
            }
            label={label}
            disabled={disabled}
        />
    }
}
