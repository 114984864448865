import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid
} from '@material-ui/core';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import moment from "moment";
import _ from "lodash";
import {loadExecutor, navLinks} from "../../services/executors";
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {loadProcessByExecutorId} from "../../services/processes";

export default
@withRouter
@withTranslation()
@connect(null,{
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    container: {
        width: 700,
    },
    item: {
        padding: 5,
    },
})
class ViewPage extends React.Component {

    state = {
        refreshTable: false,
        loading: false,
        notFound: false,
        executor: {
            id: null,
            dateCreated: null,
            type: null,
            processId: null
        },
        executorParamsTable: null
    };

    componentDidMount() {
        const { showError, t, showMessage } = this.props;

        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const executorId = reId.exec(path);

        this.setState({
            loading: true,
        }, () => {
            loadExecutor(executorId).then((response) => {
                if (response.success) {
                    this.setState({
                        executor: {
                            ...this.state.executor,
                            ...response.data
                        },
                        loading: false
                    }, () => { this.parseExecutorParams() } )
                } else if (!!response?.error) {
                    showMessage(response.error?.message);
                } else {
                    showError(t('No executor found'));
                }
            });
        });

        loadProcessByExecutorId(executorId).then((response) => {
            const { executor } = this.state;

            if (response.success)
                this.setState({
                    executor: {
                        ...executor,
                        processId: response.data?.id
                    }
                })
        })
    }

    parseExecutorParams = () => {
        const { executor } = this.state;
        let rows = [];

        if (_.size(executor.params) > 0) {
            _.forEach(executor.params, (param, name) => {
                rows.push( { name: name, value: param } );
            })

            let table = (
                <Table size="small" aria-label="Executor params">
                    <TableHead>
                        <TableRow>
                            <TableCell>Param</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )

            this.setState({
                executorParamsTable: table,
            })
        }
    }

    back = () => {
        this.props.history.push('/executor/');
    }

    goToParentProcess = () => {
        const { executor } = this.state;
        const { history, showError, t } = this.props;

        if (executor?.processId)
            history.push(`/process/view/${executor?.processId}`);
        else
            showError(t('No process id found'));
    }

    render() {
        const { t } = this.props;
        const { executor, notFound, executorParamsTable, loading } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t('Executor')}/>}>
                     <ShowField
                        label={t("Date create")}
                        value={ (executor?.dateCreated) ? moment(executor?.dateCreated).format('DD.MM.YYYY'): t('No') }
                        visible={!!executor}
                        fullWidth
                    />
                    <ShowField
                        label={t("Type")}
                        value={ (executor?.type) ? executor?.type: t('No') }
                        visible={!!executor}
                        fullWidth
                    />
                    <br />
                    <br />
                    <ActionButton
                        onClick={this.back}>{t("Back")}
                    </ActionButton>
                    <ActionButton
                        onClick={this.goToParentProcess}
                        disabled={!executor.processId}
                    >
                        {t('Go to process')}

                    </ActionButton>
                </LeftBar>
                <Content title={t('Executor')} notFoundContent={notFound}>
                    <Grid container children={executorParamsTable}></Grid>
                    { loading && <CircularProgress /> }
                </Content>
            </React.Fragment>
        );
    }
}
