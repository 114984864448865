import React from 'react';
import {Route, Switch} from "react-router";
import VisitsToWhatsAppCorrelationReportPage from "./VisitsToWhatsAppCorrelationReportPage";

class TasksPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/reports"              component={VisitsToWhatsAppCorrelationReportPage}/>
            </Switch>
        );
    }
}

export default TasksPage;