import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as cn from 'classnames';
import {connect} from "react-redux";

export default
@withStyles({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: 2,
    },
    progress: {
        height: 2,
    },
    hidden: {
        display: 'none',
    }
})
@connect(state => ({
    visible: state.backgroundProcess.visible,
}),{

})
class BackgroundProgress extends React.Component
{
    render() {
        const { classes, visible } = this.props;

        return <div className={cn(classes.root, {[classes.hidden]: !visible})}>
            <LinearProgress className={classes.progress} />
        </div>
    }
}
