import {createBrowserHistory} from 'history';
import * as queryString from 'query-string-object';

export const history = createBrowserHistory();


export function pushFilterToQuery(filter) {
    history.push(history.location.pathname + '?' + queryString.stringify(filter));
}

export function getFilterFromQuery(defaultValue = null) {
    if (history.location.search) {
        const query = history.location.search.match(/^\?/) ?
            history.location.search.substring(1) :
            history.location.search;
        return queryString.parse(query);
    }

    return defaultValue;
}