import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import DataTable from "../../components/data-table/DataTable";
import {getTaskActions, loadTask, navLinks} from "../../services/tasks";
import _ from "lodash";
import moment from "moment";
import XLSX from "xlsx";

export default
@withRouter
@withTranslation()
@connect(null,{
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    container: {
        width: 700,
    },
    item: {
        padding: 5,
    },
})
class ViewPage extends React.Component {

    state = {
        task: {
            id: null,
            name: null,
            process: {
                id: null,
                name: null,
            },
            scheduleDailyStart: null,
            scheduleDailyStop: null,
            scheduleMainStart: null,
            scheduleMainStop: null,
            actualStart: null,
            taskActionsCount: 0,
            taskActionRunningCount: 0,
            taskActionSuccessCount: 0,
            taskActionFailCount: 0
        },
        notFound: false,
        loading: true,
        refreshTable: false
    };

    columnData = [
        { id: 'id', label: 'ID' },
        {
            id: 'params',
            label: 'Parameters',
            numeric: false,
            processValue: (params) => {
                let parametersEls = [];

                _.forEach(params, (val, name) => {
                    parametersEls.push(
                        <p>{`${name}: ${val}`}</p>
                    )
                })
                return <p children={parametersEls}/>;
            }
        },
        { id: 'status', label: 'Status' },
        { id: 'dateCreated', label: 'Date created', dateFormat: "DD.MM.YYYY HH:mm" },
        { id: 'finishDate', label: 'Finish date', dateFormat: "DD.MM.YYYY HH:mm" },
        {
            id: 'resultNotes',
            label: 'Result notes',
            numeric: false,
            processValue: (notes) => {

                if ( typeof notes === 'object' ) {

                    let parametersEls = [];

                    _.forEach(notes, (note, name) => {
                        parametersEls.push(
                            <p>{`${name}: ${note}`}</p>
                        )
                    })

                    return <p children={parametersEls}/>;
                } else {
                    return <p>{notes}</p>;
                }

            }
        },
    ];

    componentDidMount() {

        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);

        loadTask(resultId).then((response) => {
            if (response.success) {
                this.setState({
                    task: response.data,
                    loading: false
                })
            }
        })

    }

    back = () => {
        this.props.history.push('/tasks/');
    }

    getTableData = (...params) => {

        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);

        return getTaskActions(...params, resultId[0]);
    };

    /*--------------------------------download file with phone numbers------------------------------------------------*/

    handleFilePhoneNumbers = () => {
        const task = this.state.task;
        const name = task.name;
        const totalCount = task.taskActionsCount;
        const data = [['Телефон', 'Статус']];

        getTaskActions('', totalCount, '', '', task.id).then(response => {
            _.forEach(response.data, row => {
                data.push([row.params.phone, row.status])
            })
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Load Parlours");
            XLSX.writeFile(new_workbook, `${name}.xlsx`);
        })
    }

    render() {
        const { t } = this.props;
        const { task, notFound, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t('Task')}/>}>
                    <ShowField
                        label={t("Id")}
                        value={ (task?.id) ? task.id : t('No') }
                        visible={!!task.id}
                        fullWidth
                    />
                    <ShowField
                        label={t("Name")}
                        value={ task.name }
                        visible={!!task?.name}
                        fullWidth
                    />
                    <ShowField
                        label={t("Process link")}
                        value={
                            (task?.process?.id) ?
                                <a onClick={() => { this.props.history.push(`/process/view/${task.process.id}`) }} href={`/process/view/${task.process.id}`}>{task.process.name}</a> :
                                t('No')
                        }
                        visible={!!task?.process?.id}
                        fullWidth
                    />
                    <ShowField
                        label={t("Actual task start")}
                        value={ (task?.actualStart) ? moment(task.actualStart).format('DD.MM.YYYY HH:mm') : t('No') }
                        visible={!!task?.actualStart}
                        fullWidth
                    />
                    <ShowField
                        label={t("Task actions count")}
                        value={ (task?.taskActionsCount) ? task.taskActionsCount : t('No') }
                        visible={!!task?.taskActionsCount}
                        fullWidth
                    />
                    <ShowField
                        label={t("Task success count")}
                        value={ (task?.taskActionSuccessCount) ? task.taskActionSuccessCount : t('No') }
                        visible={!!task?.taskActionSuccessCount}
                        fullWidth
                    />
                    <ShowField
                        label={t("Task running count")}
                        value={ (task?.taskActionRunningCount) ? task.taskActionRunningCount : t('No') }
                        visible={!!task?.taskActionRunningCount}
                        fullWidth
                        label2Lines={true}
                    />
                    <ShowField
                        label={t("Task fail count")}
                        value={ (task?.taskActionFailCount) ? task.taskActionFailCount : t('No') }
                        visible={!!task?.taskActionFailCount}
                        fullWidth
                    />
                    <br />
                    <br />
                    <ActionButton
                        onClick={this.handleFilePhoneNumbers}>{t("Download file")}
                    </ActionButton>
                    <ActionButton
                        onClick={this.back}>{t("Back")}
                    </ActionButton>
                </LeftBar>
                <Content title={t('Task')} notFoundContent={notFound}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
