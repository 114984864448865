import React from 'react';
import {purple} from "@material-ui/core/colors";
import { withStyles } from '@material-ui/core/styles';
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import {hasRole} from "../../services/user";
import {withRouter} from "react-router";
import _ from "lodash";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import {withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';

const styles = theme => ({
    navItemActive: {
        backgroundColor: purple[100],
    },
    navItem: {
        padding:  theme.spacing(1),
    },
    title: {
        fontWeight: 'bold',
        marginTop:  theme.spacing(1),
    },
    nestedList: {
        paddingBottom: 0,
        paddingTop: 0
    },
    nestedChild: {
        paddingLeft:  theme.spacing(4),
    },
});

export default
@withRouter
@withStyles(styles)
@withTranslation()
class NavigationLinks extends React.Component {

    static propTypes = {
        links: PropTypes.array.isRequired,
        fakeLink: PropTypes.string,
    };

    state = {
        open: {},
        links: []
    };

    constructor(props) {
        super(props);

        let openProps = {};

        const links = Object.values(_.reduce(props.links, (result, value) => {
            if (!hasRole(value.role)) {
                return result;
            }

            if (value.group) {
                (result[value.group] || (result[value.group] = [])).push(value);
                if (!openProps[value.group]) {
                    openProps[value.group] = props.location.pathname === value.path;
                }
            } else {
                result[value.text] = value;
            }
            return result;
        }, {}));

        this.state = {
            open: {
                ...openProps
            },
            links,
        };
    }

    handleClickCollapse = (groupName) => () => {
        this.setState(state => ({
            open: {
                ...state.open,
                [groupName]: !state.open[groupName]
            }
        }));
    };

    getLinkClass(linkPath, isChild) {
        const { classes, location } = this.props;
        return classNames( classes.navItem, {[classes.navItemActive] : linkPath === location.pathname }, {[classes.nestedChild] : isChild } );
    }

    renderNested(group) {
        const { classes, t } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment key={group[0].group}>
                <ListItem button onClick={this.handleClickCollapse(group[0].group)} className={this.getLinkClass()}>
                    <ListItemText primary={t(group[0].group)}/>
                    {open[group[0].group] ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open[group[0].group]} timeout="auto">
                    <List component="div" className={classes.nestedList}>
                        {group.map( navLinkChild => (
                            <ListItem
                                key={navLinkChild.path}
                                component={Link}
                                button
                                className={this.getLinkClass(navLinkChild.path, true)}
                                to={navLinkChild.path}
                            >
                                <ListItemText primary={t(navLinkChild.text)}/>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }

    render() {
        const { classes, fakeLink, t } = this.props;
        const { links } = this.state;

        return (
            <React.Fragment>
                <Divider/>
                <div className={classes.title}>{t("Navigation in the section")}</div>
                <List component="nav">
                    { fakeLink ?
                        <ListItem button key={fakeLink} className={classNames( classes.navItem, classes.navItemActive )}>
                            <ListItemText primary={fakeLink} />
                        </ListItem>
                        : null
                    }
                    {links.map(navLink => {
                        if (_.isArray(navLink)) {
                            return this.renderNested(navLink);
                        } else {
                            return <ListItem
                                key={navLink.path}
                                button
                                className={this.getLinkClass(navLink.path)}
                                component={Link}
                                to={navLink.path}
                            >
                                <ListItemText primary={t(navLink.text)} />
                            </ListItem>;
                        }
                    })}
                </List>
            </React.Fragment>
        );
    }
}
